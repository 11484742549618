import React, { useCallback } from 'react';
import { DatePicker } from '@heidi-pay/heidi-component-library/components';
import { useTranslation } from '@hooks';
import { localeMap } from '../../../../constants';
import { dateFormatting, dateValidationSchema } from './validation';

interface IDateOfBirthInput {
  error?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  name: string;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (date: string | Date | null) => void;
  options?: {
    allowOnlyFutureDate?: boolean;
    labelOverride?: string;
    optional?: boolean;
  };
  value?: string | Date;
}

export const DateOfBirthInput = ({
  error = '',
  inputRef = undefined,
  name,
  onBlur,
  onChange,
  options = { allowOnlyFutureDate: false },
  value,
}: IDateOfBirthInput) => {
  const { i18n, t } = useTranslation();
  const { allowOnlyFutureDate = false, labelOverride } = options || {};
  const formattedOptionalLabelOverride = labelOverride
    ? `${labelOverride}.`
    : '';
  const locale = i18n.language;
  const adapterLocale = localeMap[locale.replace('_', '-').toLowerCase()];

  const handleChange = useCallback(
    (date: string | number | Date | null) => {
      onChange(date as Date);
    },
    [onChange],
  );

  return (
    <DatePicker
      adapterLocale={adapterLocale}
      cancelButtonLabel={t(
        `fields.dateOfBirthInput.${formattedOptionalLabelOverride}cancel`,
      )}
      disableFuture={!allowOnlyFutureDate}
      error={error ? t(error) : undefined}
      format="dd/MM/yyyy"
      inputRef={inputRef}
      label={t(
        `fields.dateOfBirthInput.${formattedOptionalLabelOverride}${name}.label`,
      )}
      name={name}
      okButtonLabel={t(
        `fields.dateOfBirthInput.${formattedOptionalLabelOverride}confirm`,
      )}
      onBlur={onBlur}
      onChange={handleChange}
      sx={{ marginTop: '7px', width: '100%' }}
      value={value ? new Date(value) : null}
    />
  );
};

DateOfBirthInput.validation = dateValidationSchema;
DateOfBirthInput.formatting = dateFormatting;
