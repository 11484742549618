import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { ToggleButtonList } from '@heidi-pay/heidi-component-library/components/Buttons/ToggleButtonList';
import { BaseColour } from '@heidi-pay/heidi-component-library/enums';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  styled,
} from '@mui/material';
import { useTranslation } from '@hooks';
import {
  setMonthlyPayments,
  updatePaymentPlan,
  getMonthlyPayments,
} from '@redux/payment';
import {
  IBaseInputComponent,
  IComponentBaseOptions,
} from '@transaction/components/types';
import { useFormatters } from '@utils';
import { CopyBlock } from '../../../../components/CopyBlock';
import { IPaymentPlan } from '../../../../redux/payment/types';
import { ProductVariant } from './enums';

const FormLabelStyled = styled(FormLabel)({
  color: BaseColour.Black,
  fontWeight: 700,
  fontSize: '18px',
});

const FormControlStyled = styled(FormControl)<FormControlProps>({
  marginTop: 0,
});

interface IOption {
  amount?: string;
  displayValue?: string;
  label?: string;
  value: number;
}

export interface IOptions extends IComponentBaseOptions {
  mode: string;
  options: IOption[];
  overrideKey?: string;
  summary: {
    currency: string;
    paymentPlans: { [key: number]: IPaymentPlan };
  };
  variant?: ProductVariant;
}

export const MonthlyPaymentsToggle = ({
  error,
  onChange,
  options = {
    mode: '',
    options: [],
    summary: {
      currency: '',
      paymentPlans: [],
    },
  },
  value,
}: IBaseInputComponent<IOptions, number, string>) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    overrideKey,
    summary: { currency, paymentPlans },
  } = options;

  const { formatAmountWithCurrency } = useFormatters();
  const numberOfMonthlyPayments = useSelector(getMonthlyPayments);

  const getPaymentPlanForMonthlyPayments = useCallback(
    (monthlyPaymentsValue: number) => paymentPlans[monthlyPaymentsValue],
    [paymentPlans],
  );

  const setPaymentPlanForMonthlyPayments = useCallback(
    (monthlyPaymentsValue: number) => {
      const paymentPlan =
        getPaymentPlanForMonthlyPayments(monthlyPaymentsValue);
      dispatch(updatePaymentPlan(paymentPlan));
    },
    [dispatch, getPaymentPlanForMonthlyPayments],
  );

  useEffect(() => {
    if (value) {
      dispatch(setMonthlyPayments(value));
    }
  }, [dispatch, value]);

  useEffect(() => {
    if (numberOfMonthlyPayments > 0) {
      setPaymentPlanForMonthlyPayments(numberOfMonthlyPayments);
    }
  }, [numberOfMonthlyPayments, setPaymentPlanForMonthlyPayments]);

  const handleChange = useCallback(
    (updatedValue: number) => {
      dispatch(setMonthlyPayments(updatedValue));
      onChange(updatedValue);
    },
    [dispatch, onChange],
  );

  const getPaymentPlanLabel = useCallback(
    ({ amount, displayValue, value: term }: IOption) => {
      return amount
        ? `${formatAmountWithCurrency(amount, currency)} x ${
            displayValue || term
          }`
        : String(term);
    },
    [currency, formatAmountWithCurrency],
  );

  const paymentPlanTermOptions = useMemo(() => {
    if (!currency) {
      return [];
    }

    return options.options.map(option => ({
      label: getPaymentPlanLabel(option),
      value: option.value,
    }));
  }, [getPaymentPlanLabel, options.options, currency]);

  if (!currency) {
    return null;
  }

  const hideHeaderList = [ProductVariant.SwissCreditProduct];
  const hideHeader = hideHeaderList.includes(options.variant as ProductVariant);

  return (
    <FormControlStyled component="fieldset" fullWidth={true} size="small">
      {!hideHeader ? (
        <FormLabelStyled>
          <CopyBlock
            i18nKey={
              overrideKey
                ? `fields.monthlyPaymentsToggle.label.${options.overrideKey}.${
                    options.mode ?? 'default'
                  }`
                : `fields.monthlyPaymentsToggle.label.${options.mode}`
            }
          />
        </FormLabelStyled>
      ) : null}
      <ToggleButtonList
        onClick={handleChange}
        options={paymentPlanTermOptions.map(option => ({
          key: option.value,
          label: option.label,
        }))}
        sx={{ mt: '20px' }}
        toggleButtonListName="monthly-payment-toggle"
        value={value}
      />
      <FormHelperText error={!!error}>{t(error ?? '')}</FormHelperText>
    </FormControlStyled>
  );
};

MonthlyPaymentsToggle.validation = () =>
  yup.string().required('fields.monthlyPaymentsToggle.required');
