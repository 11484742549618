import { useCallback, useEffect, useState } from 'react';
import { useForm, Control, FieldValues } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { registerSubFormValidation } from '../../../../utils';
import { setProducts } from '../../../redux';
import { FieldNames, InstoreFormType } from '../InstoreProductForm/enums';
import { getFormSettings } from '../InstoreProductForm/formSettings/formSettings';
import { IMobilezoneProducts } from '../InstoreProductForm/formSettings/types';
import { MultiProductMobilezoneForm } from './components/MultiProductMobilezoneForm';

interface IFormOptions {
  currency?: string;
  variant?: InstoreFormType;
}

interface IInstoreMultiProductMobilezoneFormContainer {
  onChange: (values: {
    getValues: () => FieldValues;
    hasErrors: () => boolean;
    isSubmitAttempted: () => boolean;
    trigger: (name?: string | string[]) => Promise<boolean>;
  }) => void;
  options?: IFormOptions;
  submitAttempted?: boolean;
}

export const InstoreMultiProductMobilezoneFormContainer = ({
  onChange,
  options = {},
  submitAttempted = false,
}: IInstoreMultiProductMobilezoneFormContainer) => {
  const dispatch = useDispatch();
  const { currency } = options;

  const tradeInFormSettings = getFormSettings(
    InstoreFormType.MobilezoneTradeIn,
    { currency },
  );
  const notTradeInFormSettings = getFormSettings(
    InstoreFormType.DefaultFreeText,
    { currency },
  );

  const {
    defaultValues: tradeInDefaultValues,
    validationSchema: tradeInValidation,
  } = tradeInFormSettings;
  const { validationSchema: notTradeInValidation } = notTradeInFormSettings;

  const productsDynamicValidationSchema = yup.object().shape({
    products: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            ...notTradeInValidation,
            ...tradeInValidation,
          })
          .when(FieldNames.ProductSku, {
            is: (sku: string | undefined) => !sku,
            then: yup.object().shape(notTradeInValidation),
            otherwise: yup.object().shape(tradeInValidation),
          }),
      )
      .required(),
  });

  const [productsCopy, setProductsCopy] = useState(
    tradeInDefaultValues.products,
  );

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(productsDynamicValidationSchema),
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: tradeInDefaultValues.products,
  });

  useEffect(() => {
    onChange({
      getValues,
      hasErrors: () => Object.keys(errors).length !== 0,
      isSubmitAttempted: () => submitAttempted,
      trigger,
    });
  }, [errors, getValues, onChange, submitAttempted, trigger]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateStoreDebounced = useCallback(
    debounce(() => {
      const values = getValues() as Partial<{
        products: IMobilezoneProducts[];
      }>;
      dispatch(setProducts(values?.products ?? []));
    }, 800),
    [dispatch, getValues],
  );

  const productsWatch = watch('products');
  useEffect(() => {
    const previousProductsJson = JSON.stringify(productsCopy);
    const currentProductsJson = JSON.stringify(productsWatch);
    if (previousProductsJson !== currentProductsJson) {
      updateStoreDebounced();
      setProductsCopy(productsWatch as unknown as never[]);
    }
  }, [productsCopy, productsWatch, updateStoreDebounced]);

  return (
    <Grid container={true} justifyContent="center">
      <Grid item={true} xs={12}>
        <MultiProductMobilezoneForm
          control={control as Control<FieldValues>}
          currency={currency ?? ''}
          defaultValues={tradeInDefaultValues.products}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
        />
      </Grid>
    </Grid>
  );
};

InstoreMultiProductMobilezoneFormContainer.validation =
  registerSubFormValidation('instoreMultiProductMobilezoneFormContainer', true);
