import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Provider, useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { CssBaseline } from '@mui/material';
import { useI18nLanguageChange } from '@hooks/useI18nLanguageChange/useI18nLanguageChange';
import { Actions, getActionSettings } from '@utils';
import { useNetworkConnectionTracking } from '@utils/network';
import LocationWatcher from './components/LocationWatcher';
import ScrollToTop from './components/ScrollToTop';
import env from './env';
import { getGoogleAnalytics } from './externalTools';
import Home from './main/Home';
import Main from './main/Main';
import { IdentityVerification } from './mobileRedirect';
import { createStore, history } from './store';
import { ThemeProvider } from './theme';
import ErrorPage from './transaction/components/ErrorPage';
import { CheckCurrentAction } from './transaction/pages/CheckCurrentAction';
import { init } from './transaction/redux';
import TransactionRoutes from './transaction/Routes';
import '@heidi-pay/heidi-component-library/styles/index.css';
import '@heidi-pay/heidi-component-library/themes/types';

const gaTag = `https://www.googletagmanager.com/gtag/js?id=${env.GA_TOKEN}`;
const store = createStore();

function App() {
  const dispatch = useDispatch();
  const { isClientSideAction } = getActionSettings();
  useNetworkConnectionTracking();

  useEffect(() => {
    if (!isClientSideAction) {
      dispatch(init());
    }
  }, [dispatch, isClientSideAction]);

  const { language } = useI18nLanguageChange();

  return (
    <Provider store={store}>
      <ThemeProvider>
        <Helmet>
          <html lang={language} />
          <script
            data-site={env.FATHOM_TOKEN}
            defer={true}
            src="https://owl.heidipay.com/script.js"
          />
          <script async={true} src={gaTag} />
          <script>{getGoogleAnalytics(env.GA_TOKEN)}</script>
        </Helmet>
        <CssBaseline />

        <ConnectedRouter history={history} noInitialPop={true}>
          <Main>
            <LocationWatcher />
            <ScrollToTop />
            <Switch>
              <Route component={ErrorPage} exact={true} path="/404" />
              <Route
                component={IdentityVerification}
                exact={true}
                path={`/${Actions.identityVerificationInfocert}`}
              />
              <Route
                component={CheckCurrentAction}
                exact={true}
                path="/processing-please-wait"
              />
              <Route component={Home} exact={true} path="/" />
              <TransactionRoutes />
              <Redirect to="/" />
            </Switch>
          </Main>
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default function AppWrapper() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}
