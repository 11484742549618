import { call, HttpMethod } from '../utils/http';

export type ExperianItalyAnalyticsEventType =
  | 'AUTOCAPTURE_CLOSED'
  | 'AUTOCAPTURE_CAMERA_DISPLAY_STARTED'
  | 'AUTOCAPTURE_COMPLETE'
  | 'AUTOCAPTURE_STARTED'
  | 'ADD_IMAGE_FROM_FILE_SYSTEM'
  | 'ADD_IMAGE_AUTOCAPTURE'
  | 'ERROR_FAILED_TO_UPLOAD_FILE'
  | 'ERROR_FAILED_TO_PARSE_PDF_FILE'
  | 'FILE_UPLOADED_SUCCESSFULLY'
  | 'INITIALISED_AUTOCAPTURE'
  | 'INITIALISED_FILE_SELECTION'
  | 'PROCESSING_ERROR_IMAGE_BLURRY'
  | 'RESTART_PDF_FILE_SELECTION'
  | 'RETRY_FILE_SELECTION'
  | 'SELECTED_IMAGE_FROM_FILE_SYSTEM'
  | 'SELECTED_PDF_FILE_FROM_FILE_SYSTEM'
  | 'UPLOADED_IMAGE'
  | 'UPLOADED_PDF_FILE';

export interface IExperianItalyAnalyticsOptions {
  actionName?: string;
  details?: string;
}

export async function sendExperianItalyAnalytics(
  eventType: ExperianItalyAnalyticsEventType,
  options?: IExperianItalyAnalyticsOptions,
) {
  call('/api/checkout-client/experian-italy/user-analytics/', {
    method: HttpMethod.Post,
    body: JSON.stringify({
      eventType,
      ...(options ?? {}),
    }),
  }).catch(() => {});
}
