import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useTranslation } from '@hooks/useTranslation';
import { getOrderProfile } from '@instore/redux';
import { IBaseInputComponent } from '@transaction/components/types';
import { ContractType } from '../../../../enums';
import { SelectPicker } from '../SelectPicker';
import {
  IInstoreSwissAddressLivedAtYearsError,
  IInstoreSwissAddressLivedAtYearsOptions,
  IInstoreSwissAddressLivedAtYearsValue,
} from './types';

export const getValidationSchema = () =>
  yup.object().shape({
    livedAtYears: yup.string().when('hideAddressLivedAtPicker', {
      is: false,
      then: schema => schema.required('fields.selectPicker.required'),
      otherwise: schema => schema.notRequired(),
    }),
    hideAddressLivedAtPicker: yup.boolean().required(),
  });

export const InstoreSwissAddressLivedAtYears = (
  props: IBaseInputComponent<
    IInstoreSwissAddressLivedAtYearsOptions,
    IInstoreSwissAddressLivedAtYearsValue,
    IInstoreSwissAddressLivedAtYearsError
  >,
) => {
  const { t } = useTranslation();
  const orderProfile = useSelector(getOrderProfile);
  const { error, name, options, setValue, value } = props;
  const [livedAtYears, setLivedAtYears] = useState<string>('');

  const isRegulatedCredit =
    orderProfile?.contractType === ContractType.RegulatedCredit;

  const regulatedCreditOnly = options?.regulatedCreditOnly ?? false;

  const hideAddressLivedAtPicker = regulatedCreditOnly && !isRegulatedCredit;

  useEffect(() => {
    setValue(name, {
      livedAtYears,
      hideAddressLivedAtPicker,
    });
  }, [hideAddressLivedAtPicker, livedAtYears, name, setValue]);

  if (hideAddressLivedAtPicker) {
    return null;
  }

  return (
    <SelectPicker
      {...props}
      complete={Boolean(value?.livedAtYears)}
      error={
        error?.livedAtYears?.message ? t(error?.livedAtYears?.message) : ''
      }
      hasEmptyOption={true}
      onChange={setLivedAtYears}
      value={value?.livedAtYears}
    />
  );
};

InstoreSwissAddressLivedAtYears.validation = getValidationSchema;
