import {
  useState,
  useRef,
  useCallback,
  FocusEvent,
  KeyboardEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import mem from 'mem';
import * as yup from 'yup';
import { CodeInput } from '@heidi-pay/heidi-component-library/components';
import { InputFieldAdornment } from '@heidi-pay/heidi-component-library/components/InputFieldAdornment';
import { FormControl, FormHelperText, Box } from '@mui/material';

import { CopyBlock } from '@components';
import { setErrorState } from '@redux/actions';
import {
  resendVerificationCode,
  setVerifyingState,
  getIsVerifying,
} from '@redux/verification';
import { VerificationCodeTypes } from '@transaction/services/VerificationService';
import * as verificationService from '@transaction/services/VerificationService';
import { getDispatch } from '../../../../store';

const VERIFICATION_CODE_LENGTH = 6;

interface IMobileVerificationCodeInputProps {
  clearError: () => void;
  error: string | null;
  name: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  value: string;
}

export const MobileVerificationCodeInput = ({
  clearError,
  error,
  name,
  onBlur,
  onChange,
  value,
}: IMobileVerificationCodeInputProps) => {
  const { t } = useTranslation();
  const isVerifying = useSelector(getIsVerifying);
  const dispatch = useDispatch();

  const [isComplete, setIsComplete] = useState(false);
  const [codeArray, setCodeArray] = useState<string[]>(
    value ? value.split('') : Array(VERIFICATION_CODE_LENGTH).fill(''),
  );

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  }, []);

  const setInputRef = useCallback(
    (el: HTMLInputElement | null, index: number) => {
      inputRefs.current[index] = el;
    },
    [],
  );

  const handleKeyDown = useCallback(
    (index: number, e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace' && !codeArray[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    },
    [codeArray],
  );

  const resend = useCallback(() => {
    setCodeArray(Array(VERIFICATION_CODE_LENGTH).fill(''));
    clearError();
    dispatch(resendVerificationCode(VerificationCodeTypes.phoneVerification));
    setIsComplete(false);
  }, [clearError, dispatch]);

  const handleChange = useCallback(
    (index: number, inputValue: string) => {
      setCodeArray(prevCode => {
        const newCode = [...prevCode];
        newCode[index] = inputValue.slice(-1);

        if (inputValue && index < VERIFICATION_CODE_LENGTH - 1) {
          inputRefs.current[index + 1]?.focus();
        }

        const fullCode = newCode.join('');
        onChange(fullCode);

        if (fullCode.length === VERIFICATION_CODE_LENGTH) {
          clearError();
          onBlur();

          MobileVerificationCodeInput.validation()
            .isValid(fullCode)
            .then(setIsComplete);
        } else {
          setIsComplete(false);
        }

        return newCode;
      });
    },
    [onChange, clearError, onBlur],
  );

  return (
    <FormControl component="fieldset" error={!!error} fullWidth={true}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        margin="10px 0"
      >
        <Box alignItems="center" display="flex" gap={1.2}>
          {codeArray.map((digit, index) => (
            <CodeInput
              digit={digit}
              disabled={isVerifying}
              error={!!error}
              index={index}
              key={index}
              label={t('fields.codeInput.label')}
              name={name}
              onChange={handleChange}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              setInputRef={setInputRef}
            />
          ))}

          <InputFieldAdornment
            isComplete={isComplete}
            isDefaultAdornmentHidden={true}
            isError={!!error}
          />
        </Box>
      </Box>

      {error && <FormHelperText>{t(error)}</FormHelperText>}

      <CopyBlock
        i18nKey="fields.mobileVerificationCodeInput.resend"
        props={{ onClick: resend }}
      />
    </FormControl>
  );
};

MobileVerificationCodeInput.validation = () =>
  yup
    .string()
    .required('fields.mobileVerificationCodeInput.required')
    .min(
      VERIFICATION_CODE_LENGTH,
      'fields.mobileVerificationCodeInput.required',
    )
    .max(
      VERIFICATION_CODE_LENGTH,
      'fields.mobileVerificationCodeInput.required',
    )
    .test(
      'verificationCode',
      'fields.mobileVerificationCodeInput.invalid',
      mem(async (value: string | undefined) => {
        if (value?.length !== VERIFICATION_CODE_LENGTH) {
          return false;
        }

        const dispatch = getDispatch();

        try {
          const { isValid } = await verificationService.verifyCode(value);
          dispatch(setVerifyingState(false));
          return isValid;
        } catch (error) {
          dispatch(setVerifyingState(false));
          dispatch(setErrorState(error));
          return false;
        }
      }),
    );
