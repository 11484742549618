/* eslint-disable max-lines */
import { useCallback, useEffect, useRef, useState } from 'react';
import * as mitekScienceSDK from '../../../../public/lib/mitek-science-sdk';
import {
  IFrameCaptureResult,
  IComponentPreloadConfig,
} from '../../../../public/lib/types';
import env from '../../../env';
import { sendExperianItalyAnalytics } from '../../../services/ExperianItalyAnalyticsService';
import { IBaseInputComponent } from '../types';
import { AutoScanningContainer } from './actions/AutoScanningContainer';
import { DisplayController } from './actions/DisplayController';
import { LoadingContainer } from './actions/LoadingContainer';
import { ScanningResultContainer } from './actions/ScanningResultContainer';
import { SubmitContainerImageFromCapture } from './actions/SubmitContainerImageFromCapture';
import { SubmitContainerImageFromFileSystem } from './actions/SubmitContainerImageFromFileSystem';
import { SubmitContainerPdf } from './actions/SubmitContainerPdf';
import { ToggleButtons } from './actions/ToggleButtons';
import { getComponentPrealoadConfig } from './config';
import {
  MitekErrorCode,
  MitekComponentType,
  ActionName,
  FileSelectionType,
} from './enums';
import { IFileData, IState } from './types';
import { getFileExtension } from './utils/getFileExtension';

interface IMitekDocumentOcrContainerValue {
  documentBase64?: string;
  fileType?: string;
}

export type IMitekDocumentOcrContainerProps = IBaseInputComponent<
  null,
  IMitekDocumentOcrContainerValue
>;

const defaultState: IState = {
  action: ActionName.SelectProcess,
  errorCode: undefined,
  fileReaderResult: undefined,
  fileReaderResults: [],
};

export const MitekDocumentOcrContainer = ({
  onChange,
  submit,
}: IMitekDocumentOcrContainerProps) => {
  const [state, setState] = useState(defaultState);
  const selectedFiles = useRef<IFileData[]>([]);
  const fileSelectionType = useRef<FileSelectionType | null>(null);

  const addFile = useCallback(
    (fileReaderResult: string) => {
      const fileExtension = getFileExtension(fileReaderResult);
      selectedFiles.current = [
        ...selectedFiles.current,
        {
          fileReaderResult,
          fileExtension,
        },
      ];

      const imageChunks = fileReaderResult.split(',');
      const documentBase64 = imageChunks[1];
      onChange({
        documentBase64,
        fileType: fileExtension,
      });
    },
    [onChange],
  );

  const removeLastFile = useCallback(() => {
    selectedFiles.current.pop();
    onChange({
      documentBase64: undefined,
      fileType: undefined,
    });
  }, [onChange]);

  const updateStateWithError = useCallback(
    (errorCode: string) =>
      setState(currentState => ({
        ...currentState,
        errorCode: errorCode as MitekErrorCode,
      })),
    [],
  );

  const updateStateWithCurrentAction = useCallback(
    (action: ActionName) =>
      setState(currentState => ({ ...currentState, action })),
    [],
  );

  const setFileSelectionType = useCallback((type: FileSelectionType) => {
    fileSelectionType.current = type;
  }, []);

  const handleSubmit = useCallback(() => {
    if (submit) {
      submit();
    }
  }, [submit]);

  const handleOnSdkError = useCallback(
    sdkErrorEvent => updateStateWithError(sdkErrorEvent.code),
    [updateStateWithError],
  );

  const getImageSrcFromOcrResponse = useCallback(response => {
    const { failedImage, imageData } = response.response;
    return (imageData || failedImage) ?? '';
  }, []);

  const handleSdkFrameCaptureResult = useCallback(
    (result: IFrameCaptureResult) => {
      const imageSrc = getImageSrcFromOcrResponse(result);
      const { warnings } = result.response;
      const errorCode = warnings?.key as MitekErrorCode;

      addFile(imageSrc);
      updateStateWithError(errorCode);
      updateStateWithCurrentAction(ActionName.ScanResult);
    },
    [
      addFile,
      getImageSrcFromOcrResponse,
      updateStateWithCurrentAction,
      updateStateWithError,
    ],
  );

  const registerGlobalEventListeners = useCallback(() => {
    mitekScienceSDK.on('SDK_ERROR', handleOnSdkError);

    // camera finished capturing
    mitekScienceSDK.on('FRAME_CAPTURE_RESULT', handleSdkFrameCaptureResult);

    mitekScienceSDK.on('CAMERA_DISPLAY_STARTED', () =>
      sendExperianItalyAnalytics('AUTOCAPTURE_CAMERA_DISPLAY_STARTED'),
    );
  }, [handleSdkFrameCaptureResult, handleOnSdkError]);

  useEffect(() => {
    // loads the SDK features required
    const componentPreloadConfig: IComponentPreloadConfig =
      getComponentPrealoadConfig(env.MISNAP_SDK_LICENSE, [
        MitekComponentType.Documents,
      ]);
    mitekScienceSDK.cmd('COMPONENT_PRELOAD', componentPreloadConfig);

    return () => {
      mitekScienceSDK.cmd('SDK_STOP');
      mitekScienceSDK.cmd('SDK_REMOVE_LISTENERS');
    };
  }, []);

  const actions = [
    {
      visibleForActions: [ActionName.SelectProcess],
      component: ToggleButtons,
    },
    {
      visibleForActions: [ActionName.LoadingAutocapture],
      component: LoadingContainer,
    },
    {
      visibleForActions: [ActionName.AutoScanning],
      component: AutoScanningContainer,
    },
    {
      visibleForActions: [ActionName.ScanResult],
      component: ScanningResultContainer,
    },
    {
      visibleForActions: [ActionName.SubmitImageFileSystemSelection],
      component: SubmitContainerImageFromFileSystem,
    },
    {
      visibleForActions: [ActionName.SubmitImageCaptureSelection],
      component: SubmitContainerImageFromCapture,
    },
    {
      visibleForActions: [ActionName.SubmitPdfDocument],
      component: SubmitContainerPdf,
    },
  ];

  return (
    <>
      {actions.map(({ component: Component, visibleForActions }) => (
        <DisplayController
          key={visibleForActions.join('-')}
          state={state}
          visibleForActions={visibleForActions}
        >
          <Component
            errorCode={state.errorCode}
            filesData={selectedFiles.current}
            fileSelectionType={fileSelectionType.current}
            onAddFile={addFile}
            onRegisterGlobalEventListeners={registerGlobalEventListeners}
            onRemoveFile={removeLastFile}
            onSetCurrentAction={updateStateWithCurrentAction}
            onSetError={updateStateWithError}
            onSetFileSelectionType={setFileSelectionType}
            onSubmit={handleSubmit}
          />
        </DisplayController>
      ))}
    </>
  );
};
