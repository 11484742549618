import { ISwissPostLabels } from '@heidi-pay/heidi-component-library/components/SwissPostAddress';
import { useTranslation } from '@hooks/useTranslation';

interface IUseGetAddressSwissPostLabels {
  isExpandable: boolean;
  isTitleShown: boolean;
  name: string;
}

export const useGetAddressSwissPostLabels = ({
  isExpandable,
  isTitleShown,
  name,
}: IUseGetAddressSwissPostLabels): ISwissPostLabels => {
  const { t } = useTranslation();

  const getFieldLabels = (fieldName: string) => ({
    label: t(`fields.addressContainerSwissPost.subFields.${fieldName}.label`),
    errorMessage: t(
      `fields.addressContainerSwissPost.subFields.${fieldName}.invalid`,
    ),
    required: t(
      `fields.addressContainerSwissPost.subFields.${fieldName}.required`,
    ),
  });

  return {
    expandCheckbox: isExpandable
      ? t(`fields.addressContainer.${name}.expandCheckbox`)
      : '',
    addressTitle: isTitleShown
      ? t(`fields.addressContainer.${name}.label`)
      : '',
    enterZipCode: t('fields.addressContainerSwissPost.enterZipCode'),
    field: {
      city: getFieldLabels('city'),
      country: getFieldLabels('country'),
      province: getFieldLabels('province'),
      streetName: getFieldLabels('streetName'),
      streetNumber: getFieldLabels('streetNumber'),
      zipCode: getFieldLabels('zipCode'),
    },
  };
};
