import * as yup from 'yup';
import { ResidencePermit } from '../enums';
import { ISwissNationalityVerificationOptions } from '../types';
import {
  dateValidationTests,
  isNonSwiss,
  isPermitB,
  validationKey,
} from '../utils/helpers';

export const getValidationSchema = (
  name: string,
  options: ISwissNationalityVerificationOptions,
) => {
  const mandatory = !options.optional;
  return yup.object().shape({
    selectedNationality: yup.string().when([], {
      is: () => mandatory,
      then: schema => schema.required(validationKey.nationalityPickerRequired),
      otherwise: schema => schema.notRequired(),
    }),

    selectedResidencePermit: yup.string().when(['selectedNationality'], {
      is: (nationality: string) => mandatory && isNonSwiss(nationality),
      then: schema =>
        schema
          .oneOf(
            [ResidencePermit.PermitB, ResidencePermit.PermitC],
            validationKey.residencePermitOnlyBAndCAccepted,
          )
          .required(validationKey.residencePermitRequired),
      otherwise: schema =>
        schema
          .oneOf(
            [ResidencePermit.PermitB, ResidencePermit.PermitC, ''],
            validationKey.residencePermitOnlyBAndCAccepted,
          )
          .notRequired(),
    }),

    selectedDateOfEntry: yup.mixed().when(['selectedResidencePermit'], {
      is: (residencePermit: ResidencePermit) =>
        mandatory && isPermitB(residencePermit),
      then: schema =>
        schema
          .required(validationKey.dateOfEntryRequired)
          .test(dateValidationTests.basic(false))
          .test(dateValidationTests.minOneYearOld(false)),
      otherwise: schema =>
        schema
          .notRequired()
          .test(dateValidationTests.basic(true))
          .test(dateValidationTests.minOneYearOld(true)),
    }),
  });
};
