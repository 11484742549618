import { dateValidationSchema } from '../../DateOfBirthInput/validation';
import { ResidencePermit, IdentityCheckMethod } from '../enums';

export const validationKey = {
  swissManualIdentityVerificationRequired:
    'fields.swissManualIdentityVerification.required',
  nationalityPickerRequired: 'fields.nationalityPicker.required',
  residencePermitOnlyBAndCAccepted:
    'fields.selectPicker.swissManualIdentityVerificationResidencePermit.onlyBAndCAccepted',
  residencePermitRequired: 'fields.selectPicker.required',
  dateOfEntryRequired: 'fields.dateOfEntry.required',
  dateOfEntryInvalid: 'fields.dateOfEntry.invalid',
  dateOfEntryTooOld: 'fields.dateOfEntry.tooOld',
  confirmationCheckboxRequired: 'fields.confirmationCheckbox.required',
};

export const isManualVerification = (verificationMethod: string) =>
  verificationMethod === IdentityCheckMethod.ManualIdVerification;

export const isNonSwiss = (nationality: string) =>
  nationality && nationality !== 'CH';

export const isPermitB = (permit: string) => permit === ResidencePermit.PermitB;

export const dateValidationTests = {
  basic: (optional = false) => ({
    name: 'date-validation',
    message: validationKey.dateOfEntryInvalid,
    test: (value: string) => {
      if (!value && optional) {
        return true;
      }
      return dateValidationSchema('dateOfEntry', {
        allowOnlyFutureDate: false,
        optional,
      }).isValidSync(value);
    },
  }),

  minOneYearOld: (optional = false) => ({
    name: 'min-1-year-old',
    message: validationKey.dateOfEntryTooOld,
    test: (value: string) => {
      if (!value) {
        return optional;
      }

      const today = new Date();
      const oneYearAgo = new Date(today);
      oneYearAgo.setFullYear(today.getFullYear() - 1);
      return new Date(value) <= oneYearAgo;
    },
  }),
};
