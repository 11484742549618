export const AddressField = Object.freeze({
  city: 'city',
  country: 'country',
  province: 'province',
  streetName: 'streetName',
  streetNumber: 'streetNumber',
  zipCode: 'zipCode',
});

export const addressValidationFields = [
  { name: AddressField.province },
  { name: AddressField.city },
  { name: AddressField.zipCode },
  { name: AddressField.streetName },
  { name: AddressField.streetNumber },
];
