/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { setLoadingState } from '@redux';
import type { VerificationCodeType } from '@transaction/services/VerificationService';
import * as verificationService from '@transaction/services/VerificationService';
import { IVerificationState } from './types';

export const initialState: IVerificationState = {
  isVerifying: false,
};

export const resendVerificationCode = createAsyncThunk(
  'verification/resendCode',
  async (verificationCodeType: VerificationCodeType, { dispatch }) => {
    dispatch(setLoadingState(true));
    try {
      return await verificationService.generateCode(verificationCodeType);
    } finally {
      dispatch(setLoadingState(false));
    }
  },
);

const verificationSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    setVerifyingState: (state, action: PayloadAction<boolean>) => {
      state.isVerifying = action.payload;
    },
  },
});

// Selectors
export const getIsVerifying = (state: { verification: IVerificationState }) =>
  state.verification.isVerifying;

export const { setVerifyingState } = verificationSlice.actions;

export const verificationReducer = verificationSlice.reducer;
