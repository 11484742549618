import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  AddressSwissPost,
  AddressType,
  useSwissPost,
  IAddressField,
  UseFormTrigger,
} from '@heidi-pay/heidi-component-library/components/SwissPostAddress';
import { getPrefillFormCount } from '@instore/redux/selectors';
import { registerSubFormValidation } from '@utils';
import { getSwissPostResults } from '../../../services/SwissPostAddressService';
import { IBaseInputComponent, SubFormWithType } from '../../types';
import { mapFormValuesToAddressFields } from '../AddressContainerItalianPost/utils/mapFormValuesToAddressFields';
import { useGetAddressSwissPostLabels } from './hooks';
import { IAddressContainerSwissPostOptions } from './types';

export const AddressContainerSwissPost = ({
  name,
  onChange,
  options,
  submitAttempted,
  value,
}: IBaseInputComponent<
  IAddressContainerSwissPostOptions,
  SubFormWithType<IAddressField>
>) => {
  const isTitleShown = name === AddressType.BillingAddress;
  const formLabels = useGetAddressSwissPostLabels({
    name,
    isExpandable: options?.isExpandable ?? false,
    isTitleShown,
  });
  const forceRefreshCount = useSelector(getPrefillFormCount);

  const { formHandlers, formHelpers, formState, methods } = useSwissPost({
    defaultValue: value,
    getSwissPostResults,
    forceRefreshCount,
    formLabels,
  });

  const { isExpanded } = formState;
  const { control, errors, getValues, trigger } = methods;

  useEffect(
    () =>
      onChange({
        getValues: () => mapFormValuesToAddressFields(getValues()),
        trigger: trigger as UseFormTrigger<Record<string, string>>,
        hasErrors: () => Object.values(errors).length !== 0,
        isExpanded,
      }),
    [getValues, isExpanded, onChange, trigger, forceRefreshCount, errors],
  );

  return (
    <AddressSwissPost
      control={control}
      formHandlers={formHandlers}
      formHelpers={formHelpers}
      formLabels={formLabels}
      formState={formState}
      name={name}
      options={options}
      showAddressTitle={isTitleShown}
      submitAttempted={submitAttempted}
    />
  );
};

AddressContainerSwissPost.validation =
  registerSubFormValidation<IAddressField>('addressContainer');
