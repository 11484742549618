import { useCallback, useEffect } from 'react';
import { useRegisterScriptFromUri } from '@hooks';
import { addStyles } from '@utils';
import { Environment, EventType } from './enums';
import { useCustomStyles } from './hooks/useCustomStyles';
import {
  registerEventListeners,
  unregisterEventListeners,
} from './utils/eventListenerHelpers';

const WidgetId = 'infocert-onboarding-widget';
const WidgetAssetsPath = '/static/infocert';

export const WidgetStyles = `#${WidgetId} {
    display: block;
    height: 600px;
  }`;

export interface IInfocertOnboardingWidget {
  environment: Environment;
  language: string;
  onComplete: () => void;
  token: string;
}

export const InfocertOnboardingWidget = ({
  environment,
  language,
  onComplete,
  token,
}: IInfocertOnboardingWidget) => {
  const customStyles = useCustomStyles();
  const { isLoaded } = useRegisterScriptFromUri(
    '/static/infocert/build.js',
    'module',
  );

  const handleRegisterEventListeners = useCallback(() => {
    return setTimeout(() => {
      const widget = document.querySelector('onboarding-widget');
      if (!widget) {
        throw new Error(`HTML element with id ${WidgetId} not found.`);
      }
      registerEventListeners(widget);
      widget.addEventListener(EventType.Finished, onComplete);
    }, 100);
  }, [onComplete]);

  useEffect(() => {
    addStyles(WidgetStyles);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const timeout = handleRegisterEventListeners();

      return () => {
        clearTimeout(timeout);
        const widget = document.querySelector('onboarding-widget');
        if (widget) {
          unregisterEventListeners(widget);
          widget.removeEventListener(EventType.Finished, onComplete);
        }
      };
    }

    return () => {};
  }, [handleRegisterEventListeners, onComplete, isLoaded]);

  if (!isLoaded) {
    return null;
  }

  return (
    <onboarding-widget
      auth-token={token}
      custom-styles={JSON.stringify(customStyles)}
      env={environment}
      id={WidgetId}
      language={language}
      widget-base-path={WidgetAssetsPath}
    />
  );
};
