import { call, HttpMethod } from '@utils';

interface IGetPagolightProSecciUriResponse {
  secciPdfUrl: string;
}

export async function getPagolightProSecciUri(
  numberOfInstallments: number,
): Promise<IGetPagolightProSecciUriResponse> {
  // http request retry disabled because the method is called on an interval so will be retried
  return call(
    `/api/checkout-client/pagolightpro/secci-document-uri/?installments_number=${numberOfInstallments}`,
    {
      disableRetry: false,
      method: HttpMethod.Get,
    },
  );
}
