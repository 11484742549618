import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

export const ControlledField = ({
  Component,
  control,
  controlName,
  defaultValue = '',
  error = '',
  id,
  name,
  onSearchResult,
  options,
  setValue,
  trigger = () => {},
}) => {
  const {
    field: { onBlur, onChange, ref, value },
  } = useController({
    name: controlName,
    control,
    defaultValue,
  });

  return (
    <Component
      complete={!!value}
      controlName={controlName}
      error={error}
      id={id}
      inputRef={ref}
      key={name}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onSearchResult={onSearchResult}
      options={options}
      setValue={setValue}
      trigger={trigger}
      value={value}
    />
  );
};

ControlledField.propTypes = {
  Component: PropTypes.elementType.isRequired,
  control: PropTypes.object.isRequired,
  controlName: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSearchResult: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]),
  setValue: PropTypes.func.isRequired,
  trigger: PropTypes.func,
};

ControlledField.defaultProps = {
  defaultValue: '',
  error: false,
  options: {},
  trigger: () => {},
};
