import { useSelector } from 'react-redux';
import { useStatusPolling } from '@hooks';
import { getIsLoading } from '@redux';
import { checkOnfidoStatus } from '@services/onfido';
import { CopyBlock } from '../../../../components/CopyBlock';
import { HoldingPage } from '../HoldingPage';
import { IOnfidoHoldingPageProps } from './types';

export const BaseTranslationKey = 'fields.onfidoHoldingPage';

export const OnfidoHoldingPage = ({
  submit,
  submitAttempted,
  value,
}: IOnfidoHoldingPageProps) => {
  const isSubmitting = useSelector(getIsLoading);

  useStatusPolling({
    isSubmitting,
    onComplete: submit,
    pollingFunction: checkOnfidoStatus,
    retryIntervalMs: 6000,
    submitAttempted,
  });

  return (
    <HoldingPage>
      <CopyBlock i18nKey={`${BaseTranslationKey}.title`} value={value} />
      <CopyBlock i18nKey={`${BaseTranslationKey}.warning`} />
    </HoldingPage>
  );
};
