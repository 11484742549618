import { memo } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Type } from '@transaction/components/heylight/TextInputNumeric/TextInputNumeric';
import {
  TextInput,
  TextInputNumeric,
} from '../../../../transaction/components/heylight';
import { ControlledField } from './components/ControlledField';
import { FieldNames } from './enums';

export const InstoreProductFormFreeText = memo(
  ({ control, currency, errors, index, setValue }) => (
    <>
      <ControlledField
        Component={TextInput}
        control={control}
        controlName={`products.${index}.${FieldNames.ProductName}`}
        error={errors?.products?.[index]?.[FieldNames.ProductName]?.message}
        id={FieldNames.ProductName}
        name={FieldNames.ProductName}
        setValue={setValue}
      />
      <ControlledField
        Component={TextInputNumeric}
        control={control}
        controlName={`products.${index}.${FieldNames.Price}`}
        error={errors?.products?.[index]?.[FieldNames.Price]?.message}
        id={FieldNames.Price}
        name={FieldNames.Price}
        options={{
          currency,
          type: Type.monetary,
          shouldForceFormatAsMoneyField: true,
        }}
        setValue={setValue}
      />
      <ControlledField
        Component={TextInput}
        control={control}
        controlName={`products.${index}.${FieldNames.AdditionalDetails}`}
        id={FieldNames.AdditionalDetails}
        name={FieldNames.AdditionalDetails}
        options={{ hidden: true }}
        setValue={setValue}
      />
    </>
  ),
);

InstoreProductFormFreeText.propTypes = {
  control: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  errors: PropTypes.object,
  index: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};

export const validation = () =>
  yup
    .object()
    .transform(form => {
      if (form.submitAttempted) {
        form.trigger();
      }
      const formValues = form.getValues();
      return { ...form, ...formValues };
    })
    .test('instoreProductFormFreeText', 'ERROR', form => {
      return !form?.hasErrors();
    })
    .nullable();

InstoreProductFormFreeText.displayName = 'InstoreProductFormFreeText';
InstoreProductFormFreeText.validation = validation;
