import { IPaymentPlan } from '@redux/payment/types';
import { call } from '@utils';

export async function loadPaymentPlan(
  monthlyPayments: number,
  paymentMethod: string,
): Promise<IPaymentPlan> {
  return call(
    `/api/checkout-client/schedule/?monthlyPayments=${monthlyPayments}&paymentMethod=${paymentMethod}`,
  );
}
