import { call, HttpMethod } from '@utils/http';
import { IPollingResponse } from 'src/transaction/services/types';

export async function checkOnfidoStatus() {
  // http request retry disabled because the method is called on an interval so will be retried
  return call<IPollingResponse>('/api/checkout-client/onfido/status/', {
    method: HttpMethod.Get,
    disableRetry: true,
  });
}
