import { useSelector } from 'react-redux';
import { PaymentPlanTable } from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { Grid } from '@mui/material';
import { useTranslation } from '@hooks';
import {
  getOrderProfile,
  getOrderProfileContractType,
} from '@instore/redux/selectors';
import { getPaymentPlan, getMonthlyPayments } from '@redux/payment';
import { getOriginatorUuid } from '@redux/selectors';
import { useFormatters } from '@utils';
import { ContractType } from '../../../../enums';
import { IBaseComponent } from '../../types';
import { ScheduleTableHeadline } from './components/ScheduleTableHeadline';
import { IPaymentPlanHeyLightOptions } from './types';
import { getPaymentPlanFields } from './utils/getPaymentPlanFields';
import { getScheduleRows } from './utils/getScheduleRows';

interface IPaymentPlanBaseProps
  extends IBaseComponent<IPaymentPlanHeyLightOptions, unknown> {
  rowSpacing?: 'small' | 'large';
}

export const PaymentPlanBase = ({
  options = {},
  rowSpacing = 'small',
}: IPaymentPlanBaseProps) => {
  const { t: translate } = useTranslation();
  const formatters = useFormatters();
  const {
    schedule,
    summary: {
      apr, // pagolight pro filed
      creditAmount,
      currency,
      initialBalance,
      initialPayment,
      interest, // pagolight pro field
      nominalInterestRate, // mobilezone field
      payMonthly,
      payNow,
      refreshPrice,
      residualAmount,
      residualPayment,
      total,
      totalAmountPayable,
      totalPricePlusInterest,
    },
  } = useSelector(getPaymentPlan);
  const { instore, variant } = options;
  const orderProfile = useSelector(getOrderProfile);
  const monthlyPayments = useSelector(getMonthlyPayments);
  const contractType = useSelector(getOrderProfileContractType);
  const originatorUuid = useSelector(getOriginatorUuid);
  const showCreditFields = !instore || contractType !== ContractType.Factoring;
  const orderProfileRequired = Boolean(options.instore && !orderProfile);

  const computedInterest =
    apr && nominalInterestRate
      ? { apr, nominalInterestRate, interestAmount: apr }
      : interest;

  if (!currency) {
    return null;
  }

  const enableSameInstallmentNumberForDownPayment = [
    'italyBnpl',
    'italyCredit',
    'swissMobilezone',
  ].includes(variant);

  const paymentPlanTableRows = getPaymentPlanFields({
    filters: {
      isRegulatedProduct: false,
      originatorUuid,
      variant,
    },
    formatters,
    scheduleData: {
      creditAmount,
      currency,
      initialBalance,
      initialPayment,
      interest: computedInterest,
      monthlyPayments,
      nominalInterestRate: 0,
      numberOfInstallments: schedule.length,
      orderProfileRequired,
      payMonthly,
      payNow,
      refreshPrice,
      residualPayment,
      residualAmount,
      showCreditFields,
      schedule,
      total,
      totalAmountPayable,
      totalPricePlusInterest,
    },
    options,
    translate,
  });

  const scheduleTableRows = getScheduleRows({
    currency,
    enableSameInstallmentNumberForDownPayment,
    formatters,
    payNowAmount: payNow?.amount,
    schedule,
    selectedNumberOfInstallments: monthlyPayments,
  });

  if (!paymentPlanTableRows) {
    return null;
  }

  // in IT we always display the total number of installments including the down payment
  const numberOfInstallmentsWithoutDownPayment =
    enableSameInstallmentNumberForDownPayment &&
    monthlyPayments !== schedule.length
      ? schedule.length + 1
      : schedule.length;

  const Headline = (
    <ScheduleTableHeadline
      currency={currency}
      numberOfInstallments={numberOfInstallmentsWithoutDownPayment}
      totalAmountDue={totalAmountPayable ?? total}
      variant={variant}
    />
  );

  return (
    <Grid container={true}>
      <PaymentPlanTable
        rows={paymentPlanTableRows}
        rowSpacing={rowSpacing}
        scheduleHeadline={Headline}
        scheduleRows={scheduleTableRows}
      />
    </Grid>
  );
};
