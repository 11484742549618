export enum InstoreFormType {
  Category = 'category',
  Default = 'default',
  DefaultFreeText = 'defaultFreeText',
  MobilezoneTradeIn = 'MobilezoneTradeIn',
}

export enum FieldNames {
  AdditionalDetails = 'productAdditionalDetails',
  Discount = 'discount',
  InstoreProductSearchTextInput = 'instoreProductSearchTextInput',
  OrderProfileDetails = 'orderProfileDetails',
  Price = 'price',
  ProductIdentity = 'productIdentification',
  ProductName = 'productName',
  ProductSku = 'productSku',
  ProductType = 'productType',
  ProductVariant = 'productVariant',
  Quantity = 'quantity',
  ResidualValue = 'residualValue',
  TotalPrice = 'totalPrice',
}

export enum ProductType {
  NotTradeIn = 'NOT_TRADE_IN',
  TradeIn = 'TRADE_IN',
}
