import * as Sentry from '@sentry/react';
import { Environment } from '@components/InfocertOnboardingWidget/enums';
import { SentrySeverity } from '@utils';

interface IWidgetParameters {
  environment: Environment;
  lang: string;
  mobileOtc: string;
}

export const getWidgetParametersFromSearch = (
  search: string,
): IWidgetParameters | null => {
  const params = new URLSearchParams(search);
  const mobileOtc = params.get('mobileOtc');
  const environment = params.get('environment');
  const lang = params.get('lang');

  if (!mobileOtc || !environment || !lang) {
    Sentry.captureMessage(
      'Failed to load mobile Infocert identity verification flow due to missing parameters.',
      SentrySeverity.error,
    );
    return null;
  }

  return {
    environment: environment as Environment,
    lang,
    mobileOtc,
  };
};
