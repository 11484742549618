import { EventType } from '../enums';

const noSideEffectEventTypes = Object.values(EventType).filter(
  eventType => eventType !== EventType.Finished,
);

const logEvent = (eventType: string, payload?: unknown) =>
  // eslint-disable-next-line no-console
  console.log('Infocert event', eventType, payload ?? '');

export const registerEventListeners = (element: Element) =>
  noSideEffectEventTypes.forEach(eventType =>
    element.addEventListener(eventType, (payload?: unknown) =>
      logEvent(eventType, payload),
    ),
  );

export const unregisterEventListeners = (element: Element) =>
  noSideEffectEventTypes.forEach(eventType =>
    element.removeEventListener(eventType, (payload?: unknown) =>
      logEvent(eventType, payload),
    ),
  );
