import { useCallback, useEffect, useState } from 'react';

export const useRegisterScriptFromUri = (
  uri?: string,
  type = 'application/javascript',
) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleScriptEvents = useCallback(e => {
    setIsLoaded(e.type === 'load');
  }, []);

  const addListeners = useCallback(
    script => {
      script.addEventListener('load', handleScriptEvents);
      script.addEventListener('error', handleScriptEvents);
    },
    [handleScriptEvents],
  );

  const removeListeners = useCallback(
    script => {
      script.removeEventListener('load', handleScriptEvents);
      script.removeEventListener('error', handleScriptEvents);
    },
    [handleScriptEvents],
  );

  const getScriptElement = useCallback(
    () => document.querySelector(`script[src="${uri}"]`),
    [uri],
  );

  useEffect(() => {
    if (uri) {
      const scriptQueryResult = getScriptElement();
      if (scriptQueryResult) {
        addListeners(scriptQueryResult);
      } else {
        const script = document.createElement('script');
        script.type = type;
        script.src = uri;
        script.async = true;

        document.body.appendChild(script);

        addListeners(script);
      }

      return () => {
        const registeredScriptElement = getScriptElement();

        removeListeners(registeredScriptElement);

        registeredScriptElement.remove();
      };
    }

    return () => null;
  }, [addListeners, getScriptElement, removeListeners, type, uri]);

  return { isLoaded };
};
