import * as yup from 'yup';
import { ValueOf } from '@appTypes';
import { AddressField } from './constants';

interface IValidatorOptions {
  name: ValueOf<typeof AddressField>;
  optional?: boolean;
}

export const buildValidator = ({
  name,
  optional,
}: IValidatorOptions): yup.AnyObjectSchema => {
  if (optional) {
    return yup.object().nullable();
  }

  if (name === AddressField.streetNumber) {
    return yup.object({
      value: yup
        .string()
        .nullable()
        .required(`fields.addressContainer.subFields.${name}.required`)
        .max(6, `fields.addressContainer.subFields.${name}.maxLength`),
    });
  }

  if (name === AddressField.zipCode) {
    return yup.object({
      value: yup
        .string()
        .nullable()
        .required(`fields.addressContainer.subFields.${name}.required`)
        .max(5, `fields.addressContainer.subFields.${name}.maxLength`),
    });
  }

  return yup.object({
    value: yup
      .string()
      .required(`fields.addressContainer.subFields.${name}.required`),
  });
};

export interface IFieldDefinition {
  name: ValueOf<typeof AddressField>;
  optional?: boolean;
}

export const buildSchema = (
  fields: IFieldDefinition[],
): Record<string, yup.AnyObjectSchema> => {
  return Object.fromEntries(
    fields.map(field => [field.name, buildValidator(field)]),
  );
};
