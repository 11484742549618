import { objectKeysToSnakeCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToSnakeCase';
import {
  Choices,
  IAddressField,
} from '@heidi-pay/heidi-component-library/components/SwissPostAddress';
import { call, HttpMethod } from '../../utils';

export const getSwissPostResults = (fields: IAddressField) =>
  call<Choices>('/api/checkout-client/addresses/swiss/autocomplete', {
    method: HttpMethod.Post,
    body: JSON.stringify(objectKeysToSnakeCase(fields)),
    disableRetry: false,
  });
