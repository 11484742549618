import { IPollingResponse } from 'src/transaction/services/types';
import { call, HttpMethod } from '../utils/http';

export interface ICurrentActionResponse extends IPollingResponse {
  actionName: string;
}

export async function checkCurrentWaterfallAction() {
  // http request retry disabled because the method is called on an interval so will be retried
  return call<ICurrentActionResponse>(
    '/api/checkout-client/action/current-action/',
    {
      method: HttpMethod.Get,
      disableRetry: true,
    },
  );
}
