import { Fragment, useCallback, useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Form } from '@heidi-pay/heidi-component-library/components';
import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider, Grid, styled, Button } from '@mui/material';
import { useTranslation } from '@hooks';
import { SelectPicker } from '../../../../../transaction/components/heylight';
import { FieldNames, ProductType } from '../../InstoreProductForm/enums';
import { getMobilezoneFormType } from '../utils/getMobilezoneFormType';

const StyledDivider = styled(Divider)`
  opacity: 0.5;
  margin: 2rem 40px 2rem 0;
`;

export const MultiProductMobilezoneForm = ({
  control,
  currency,
  defaultValues,
  errors,
  getValues,
  setValue,
  watch,
}) => {
  const { t } = useTranslation();
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'products',
  });

  useEffect(() => {
    append(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [productFormTypes, setProductFormTypes] = useState([
    ProductType.TradeIn,
  ]);

  const handleProductTypeChange = useCallback(
    (index, value) => {
      setProductFormTypes(prev => {
        const updatedFormTypes = [...prev];
        updatedFormTypes[index] = value;
        return updatedFormTypes;
      });
    },
    [setProductFormTypes],
  );

  const handleAddForm = useCallback(() => {
    append(defaultValues);
    setProductFormTypes(prev => [...prev, ProductType.TradeIn]);
  }, [append, defaultValues, setProductFormTypes]);

  const handleRemove = index => remove(index);

  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        {fields.map((field, index) => {
          const ProductForm = getMobilezoneFormType(productFormTypes[index]);
          return (
            <Fragment key={field.id}>
              {index > 0 ? <StyledDivider /> : null}
              <Grid alignItems="center" container={true}>
                <Grid item={true} xs={6}>
                  <Form>{`${t('fields.productSelect.product.label')} ${
                    index + 1
                  }`}</Form>
                </Grid>
                {index > 0 && (
                  <Grid item={true} textAlign="end" xs={6}>
                    <Button
                      onClick={() => handleRemove(index)}
                      size="large"
                      startIcon={<DeleteIcon />}
                      sx={{ py: 0, textTransform: 'none' }}
                      variant="text"
                    >
                      {t('fields.instoreProductForm.remove')}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <SelectPicker
                hasEmptyOption={false}
                name={FieldNames.ProductType}
                onChange={value => handleProductTypeChange(index, value)}
                options={{
                  items: [ProductType.TradeIn, ProductType.NotTradeIn],
                }}
                value={productFormTypes[index]}
              />
              <ProductForm
                control={control}
                currency={currency}
                errors={errors}
                getValues={getValues}
                index={index}
                setValue={setValue}
                watch={watch}
              />
            </Fragment>
          );
        })}
      </Grid>
      <Grid item={true} textAlign="center" xs={12}>
        <StyledDivider />
        <BaseButton onClick={handleAddForm} variant="outlined">
          {t(
            'fields.instoreMultiProductMobilezoneFormContainer.multiProductForm.add',
          )}
        </BaseButton>
      </Grid>
    </Grid>
  );
};

MultiProductMobilezoneForm.propTypes = {
  control: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  defaultValues: PropTypes.object.isRequired,
  errors: PropTypes.object,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
};
