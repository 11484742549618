import { useEffect, useRef, useState } from 'react';
import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { useTranslation } from '@hooks';
import { IBaseInputComponent } from '@transaction/components/types';
import { formatOptionalLabel } from '@utils/formatters';
import { dateFormatting } from '../DateOfBirthInput/validation';
import { NationalityPicker } from '../NationalityPicker';
import { SelectPicker } from '../SelectPicker';
import { DateOfEntryInput } from './DateOfEntryInput';
import { ResidencePermit } from './enums';
import {
  ISwissNationalityVerificationError,
  ISwissNationalityVerificationOptions,
  ISwissNationalityVerificationValue,
} from './types';
import { isNonSwiss, isPermitB } from './utils/helpers';
import { getValidationSchema } from './validation/getValidationSchemaNationality';

export const SwissNationalityVerification = (
  props: IBaseInputComponent<
    ISwissNationalityVerificationOptions,
    ISwissNationalityVerificationValue,
    ISwissNationalityVerificationError
  >,
) => {
  const { t } = useTranslation();
  const { error, inputRef, onChange, options, setValue, value } = props;

  const dateOfEntryInputRef = useRef<HTMLInputElement>(null);
  const [nationality, setNationality] = useState<string>(
    value?.selectedNationality ?? '',
  );
  const [residencePermit, setResidencePermit] = useState<string>(
    value?.selectedResidencePermit ?? '',
  );
  const [dateOfEntry, setDateOfEntry] = useState<Nullable<Date>>(
    value?.selectedDateOfEntry ?? null,
  );

  const optional = options?.optional ?? false;

  useEffect(() => {
    const formattedDate = dateOfEntry ? dateFormatting(dateOfEntry) : undefined;
    onChange({
      selectedNationality: nationality,
      selectedResidencePermit: isNonSwiss(nationality)
        ? residencePermit
        : undefined,
      selectedDateOfEntry:
        isNonSwiss(nationality) && isPermitB(residencePermit)
          ? (formattedDate as Date)
          : undefined,
    });
  }, [
    dateOfEntry,
    nationality,
    props.name,
    residencePermit,
    setValue,
    onChange,
  ]);

  return (
    <>
      <NationalityPicker
        {...props}
        error={error?.selectedNationality?.message}
        name="swissManualIdentityVerificationNationality"
        onChange={setNationality}
        options={{
          preferredNationalities: ['CH'],
          optional,
        }}
        value={nationality}
      />
      {isNonSwiss(nationality) ? (
        <SelectPicker
          {...props}
          complete={Boolean(residencePermit)}
          error={error?.selectedResidencePermit?.message}
          hasEmptyOption={true}
          inputRef={inputRef}
          label={formatOptionalLabel({
            labelKey:
              'fields.selectPicker.swissManualIdentityVerificationResidencePermit.label',
            translate: t,
            optional,
          })}
          name="swissManualIdentityVerificationResidencePermit"
          onChange={setResidencePermit}
          options={{
            items: [
              ResidencePermit.PermitB,
              ResidencePermit.PermitC,
              ResidencePermit.OtherResidencePermit,
            ],
          }}
          value={residencePermit}
        />
      ) : null}
      {isNonSwiss(nationality) && isPermitB(residencePermit) ? (
        <DateOfEntryInput
          error={error?.selectedDateOfEntry?.message}
          inputRef={dateOfEntryInputRef}
          name="swissManualIdentityVerificationDateOfEntry"
          onChange={setDateOfEntry}
          optional={optional}
          value={dateOfEntry}
        />
      ) : null}
    </>
  );
};

SwissNationalityVerification.validation = getValidationSchema;
