/* eslint-disable sonarjs/cognitive-complexity */
// TODO eslint migration - fix dependency array missing props
/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { FeatureFlag, useFeatureFlags, useTranslation } from '@hooks';
import {
  overrideEventValue,
  toKeyValuePairOrNull,
} from '../utils/valueFormatters';
import { ChainedAutocompleteIcon } from './ChainedAutocompleteIcon';

const ChainedAutocomplete = forwardRef(
  (
    {
      disabled = false,
      error = false,
      errorKey,
      fetchItems,
      fetchOnInitialLoad = false,
      fetchOnUpstreamFilterChange = false,
      freeSolo = false,
      id,
      inputType,
      labelKey,
      matchFromStart = false,
      name,
      onBlur,
      onChange,
      upstreamFilter = null,
      value = null,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const initialOptions = value ? [value] : [];

    // TODO: At the moment we have two different shapes to value coming from AddressContainerPost
    // and AddressContainerItalianPostOcr. With AddressContainerPost, to ensure prefilling
    // works, we use a shape of {value: 'value', displayValue:'displayValue'} for each field.
    // With AddressContainerItalianPostOcr, we use a string. This should be unified so that we follow
    // using  {value: 'value', displayValue:'displayValue'} shape for both

    const initialValueRef = useRef(
      typeof value === 'string' ? value : value?.value,
    );

    const [options, setOptions] = useState(initialOptions);
    const [option, setOption] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const isUpstreamFilterEmpty = !upstreamFilter && !fetchOnInitialLoad;
    const isDisabled = disabled || isUpstreamFilterEmpty;
    const filterOptions = createFilterOptions({
      matchFrom: matchFromStart ? 'start' : 'any',
    });

    const { flagEnabled } = useFeatureFlags();

    const isProxyEnabled = flagEnabled(
      FeatureFlag.IsItalianPostalServiceProxyEnabled,
    );

    const handleFetchItems = filter => {
      setIsLoading(true);
      fetchItems(isProxyEnabled, filter, upstreamFilter?.key)
        .then(result => {
          setOptions(result);
        })
        .finally(() => setIsLoading(false));
    };

    useEffect(() => {
      if (options.length > 0 && !option?.key && initialValueRef.current) {
        const matchingOption = options.find(item =>
          typeof item === 'string'
            ? item.toLowerCase() === initialValueRef.current?.toLowerCase()
            : item.value.toLowerCase() ===
              initialValueRef.current?.toLowerCase(),
        );

        if (matchingOption) {
          setOption(matchingOption);
          onChange(matchingOption);
        }
      }
    }, [options]);

    const fetchDataDebounced = useCallback(
      debounce(filter => handleFetchItems(filter), 300),
      [upstreamFilter],
    );

    const resetField = () => {
      if (!freeSolo || options.includes(option)) {
        onChange(null);
        setOption({});
      }
      setOptions([]);
    };

    // Will fetch when the component loads. Useful for the first chained filter
    useEffect(() => {
      if (fetchOnInitialLoad) {
        fetchDataDebounced();
      }
    }, [fetchOnInitialLoad]);

    // Will reset the state of the field when the upstream filter changes
    // Will fetch new data if the filter changes and has a value assigned
    useEffect(() => {
      resetField();

      // We only call the italian post API when there's a default value to search by
      // or when the user types a search key in the field.
      const isFilterOnInitialValueField =
        !fetchOnUpstreamFilterChange &&
        initialValueRef.current &&
        upstreamFilter?.key;

      // We call the italian post API when the upstream field has changed and get
      // the full result set for the uppstream field value. (ex. post codes for city)
      const isFilterOnLoadField =
        fetchOnUpstreamFilterChange && upstreamFilter?.key;

      if (isFilterOnInitialValueField) {
        fetchDataDebounced(typeof value === 'string' ? value : value?.value);
      } else if (isFilterOnLoadField && !isUpstreamFilterEmpty) {
        fetchDataDebounced(upstreamFilter.key);
      }
    }, [fetchOnUpstreamFilterChange, upstreamFilter]);

    const handleOnChange = (event, selectedOption) => {
      setOption(selectedOption);
      onChange(overrideEventValue(event, selectedOption));
    };

    // Will fetch when the input changes if the field is not dependent on an upstream filter
    const handleOnInputChange = event => {
      const currentInput = event?.target?.value;
      if (!fetchOnUpstreamFilterChange && currentInput) {
        fetchDataDebounced(currentInput);
      }

      // Free input fields will not trigger the on change event because there will be no selection
      // OnChange has to be triggered manualy on key input. The field should not reset on fetch
      if (freeSolo && event) {
        const keyValuePair = toKeyValuePairOrNull(currentInput);
        onChange(overrideEventValue(event, keyValuePair));
      }
    };

    return (
      <FormControl error={true} fullWidth={true}>
        <Autocomplete
          autoComplete={false}
          autoHighlight={true}
          clearIcon={null}
          disabled={isDisabled}
          filterOptions={filterOptions}
          forcePopupIcon={false}
          freeSolo={freeSolo}
          fullWidth={true}
          getOptionLabel={selectedOption => selectedOption?.displayValue || ''}
          id={id}
          inputref={ref}
          name={name}
          noOptionsText={t('fields.addressContainer.noMatches')}
          onBlur={onBlur}
          onChange={handleOnChange}
          onInputChange={handleOnInputChange}
          options={options}
          renderInput={params => (
            <TextField
              sx={{
                '> .MuiInputBase-root.MuiInputBase-adornedEnd': { pr: '6px' },
              }}
              {...params} // eslint-disable-line  react/jsx-props-no-spreading
              error={error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <IconButton
                    disabled={true}
                    size="small"
                    sx={{ paddingX: '4px', paddingY: 0 }}
                  >
                    <ChainedAutocompleteIcon
                      hasError={error}
                      isComplete={!!value && !error}
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                    />
                  </IconButton>
                ),
                type: inputType,
              }}
              label={t(labelKey)}
            />
          )}
          value={option}
        />
        {error ? <FormHelperText>{t(errorKey)}</FormHelperText> : null}
      </FormControl>
    );
  },
);

ChainedAutocomplete.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorKey: PropTypes.string,
  fetchItems: PropTypes.func.isRequired,
  fetchOnInitialLoad: PropTypes.bool,
  fetchOnUpstreamFilterChange: PropTypes.bool,
  freeSolo: PropTypes.bool,
  id: PropTypes.string,
  inputType: PropTypes.string,
  labelKey: PropTypes.string.isRequired,
  matchFromStart: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  upstreamFilter: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ]),
};

ChainedAutocomplete.displayName = 'ChainedAutocomplete';

export default ChainedAutocomplete;
