export enum IdentityCheckMethod {
  AutoIdVerification = 'AUTO_ID_VERIFICATION',
  ManualIdVerification = 'MANUAL_ID_VERIFICATION',
  ManualIdVerificationUploadMandatory = 'MANUAL_ID_VERIFICATION_UPLOAD_MANDATORY',
}

export enum ResidencePermit {
  NonResident = 'NON_RESIDENT',
  OtherResidencePermit = 'OTHER_RESIDENT_PERMIT',
  PermitB = 'PERMIT_B',
  PermitC = 'PERMIT_C',
  SwissNational = 'SWISS_NATIONAL',
}
