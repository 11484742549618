import { lazy } from 'react';

// instore components
import { InstoreProductSelectStandalone } from '@instore/components';

import {
  ChangeCardComplete,
  CountdownTimer,
  InstorePaymentSummaryMobilezone,
  IntroPagolightPro,
  IntrumIframeContainer,
  IntrumSigningHoldingContainer,
  PagolightHoldingContainer,
  PaymentMethodBlock,
  RedirectComponent,
  ScrollableContainer,
  SecciLink,
  SecciLinkLegalNotice,
  SpidCallbackProcessor,
  SPIDWelcomeContainer,
  TermsAndConditionsButtonGroupPicker,
  HelpTooltip,
  MerchantRedirectButton,
} from './components';
import CustomerAgreementPagolightPro from './components/CustomerAgreementPagolightProDeprecated';
import HiddenField from './components/HiddenField';
import HiddenMonthlyPaymentsField from './components/HiddenMonthlyPaymentsField';
import ImageRow from './components/ImageRow';
import InstorePaymentSummary from './components/InstorePaymentSummary';
import LoginContainer from './components/LoginContainer';
import { MitekDocumentOcrContainer } from './components/MitekDocumentOcrContainer/MitekDocumentOcrContainer';
import { OrderSummary } from './components/OrderSummary';
import PaymentGatewayContainer from './components/PaymentGatewayContainer';
import RadioButtons from './components/RadioButtons';
import Spacer from './components/Spacer';
import Spinner from './components/Spinner';
import TextBlock from './components/TextBlock';
import WaitingBot from './components/WaitingBot';

export default {
  ChangeCardComplete,
  CountdownTimer,
  CustomerAgreementPagolightPro,
  HelpTooltip,
  HiddenField,
  HiddenMonthlyPaymentsField,
  ImageRow,
  InfocertIdentityContainer: lazy(() =>
    import('./components/InfocertIdentityContainer').then(module => ({
      default: module.InfocertIdentityContainer,
    })),
  ),
  InfocertIdentityHoldingContainer: lazy(() =>
    import('./components/InfocertIdentityHoldingContainer').then(module => ({
      default: module.InfocertIdentityHoldingContainer,
    })),
  ),
  InstoreMerchantRedirectQR: lazy(() =>
    import('@instore/components/InstoreMerchantRedirectQR').then(module => ({
      default: module.InstoreMerchantRedirectQR,
    })),
  ),
  InstorePaymentSummary,
  InstorePaymentSummaryMobilezone,
  InstoreProductSelectStandalone,
  IntroPagolightPro,
  IntrumIframeContainer,
  IntrumSigningHoldingContainer,
  LoginContainer,
  MerchantRedirectButton,
  MitekDocumentOcrContainer,
  OnfidoItalianHealthCardWelcomeScreenContainer: lazy(() =>
    import('./components/OnfidoItalianHealthCardWelcomeScreenContainer'),
  ),
  OrderSummary,
  OnfidoContainerLatest: lazy(() =>
    import('./components/OnfidoContainerLatest'),
  ),
  OnfidoContainerV14: lazy(() =>
    import('./components/OnfidoContainerLatest/OnfidoContainerV14'),
  ),
  PagolightHoldingContainer,
  PaymentMethodBlock,
  PaymentGatewayContainer,
  PDFDocumentViewer: lazy(() => import('./components/PDFDocumentViewer')),
  RadioButtons,
  RedirectComponent,
  ScrollableContainer,
  SecciLink,
  SecciLinkLegalNotice,
  Spacer,
  SpidCallbackProcessor,
  SPIDWelcomeContainer,
  Spinner,
  TermsAndConditionsButtonGroupPicker,
  TextBlock,
  WaitingBot,
};
