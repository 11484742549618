/* eslint-disable max-lines */
import debounce from 'lodash.debounce';
import { AnyAction } from 'redux';
import { createActions, handleActions } from 'redux-actions';
import { ThunkDispatch } from 'redux-thunk';
import { PayloadAction } from '@reduxjs/toolkit';
import { resetPaymentPlan, updatePaymentPlan } from '@redux/payment';
import { resetErrorState, setLoadingState, setErrorState } from '../../redux';
import { AppDispatch, IRootState } from '../../store';
import { actionCreator } from '../../utils';
import * as instoreService from '../services/InstoreService';
import {
  IAmount,
  ILoadPaymentSummary,
  IOrderProfile,
  IPreviousOrder,
  IPreviousOrderDetails,
  IProduct,
  IProductObject,
  IVariant,
} from '../services/types';
import {
  getIsPaymentInfoValid,
  getIsPricingStructureCodeRequired,
  getOrderProfileUuid,
  getPaymentInfo,
  getPricingStructureCode,
  getProductSku,
  getSelectedNumberOfInstallments,
} from './selectors';
import { getSummaryData } from './utils/getSummaryData';
import { incrementStateField } from './utils/incrementStateField';

export * from './selectors';

export interface IInstoreState {
  amount: IAmount;
  availableProducts: IProduct[];
  countriesFromInstore: string[];
  depositOverrideAmount: IAmount | null;
  isManualSigning: boolean | null;
  isPricingStructureCodeRequired: boolean;
  orderDiscountAmount: IAmount | null;
  orderProfile: IOrderProfile | null;
  orderProfiles: IOrderProfile[] | null;
  paymentSummary: ILoadPaymentSummary | null;
  prefillFormCount: number;
  previousOrders: IPreviousOrder[];
  pricingStructureCode: string | null;
  productDetails: IProduct | null;
  productSku: string;
  products: IProductObject[];
  selectedBillingCountry: string;
  selectedNumberOfInstallments: number[] | null;
  selectedPreviousOrder: string | null;
  selectedPreviousOrderDetails: IPreviousOrderDetails | null;
  selectedProductVariant: IVariant | null;
  selectedShippingCountry: string;
}

const defaultAmount = {
  amount: '',
  currency: '',
};

export const initialState: IInstoreState = {
  amount: defaultAmount,
  availableProducts: [],
  countriesFromInstore: [],
  isManualSigning: null,
  isPricingStructureCodeRequired: false,
  orderDiscountAmount: null,
  depositOverrideAmount: null,
  orderProfile: null,
  orderProfiles: [],
  paymentSummary: null,
  prefillFormCount: 0,
  previousOrders: [],
  pricingStructureCode: null,
  productDetails: null,
  productSku: '',
  products: [],
  selectedBillingCountry: '',
  selectedPreviousOrder: null,
  selectedPreviousOrderDetails: null,
  selectedProductVariant: null,
  selectedShippingCountry: '',
  selectedNumberOfInstallments: null,
};

const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
const UPDATE_AMOUNT = 'UPDATE_AMOUNT';
const UPDATE_AVAILABLE_PRODUCTS = 'UPDATE_AVAILABLE_PRODUCTS';
const UPDATE_ORDER_PROFILE = 'UPDATE_ORDER_PROFILE';
const UPDATE_ORDER_PROFILES = 'UPDATE_ORDER_PROFILES';
const UPDATE_PREVIOUS_ORDERS = 'UPDATE_PREVIOUS_ORDERS';
const UPDATE_PRICING_STRUCTURE_CODE = 'UPDATE_PRICING_STRUCTURE_CODE';
const UPDATE_PRODUCT_SKU = 'UPDATE_PRODUCT_SKU';
const UPDATE_PRODUCT_DETAILS = 'UPDATE_PRODUCT_DETAILS';
const UPDATE_SELECTED_SHIPPING_COUNTRY = 'UPDATE_SELECTED_SHIPPING_COUNTRY';
const UPDATE_SELECTED_BILLING_COUNTRY = 'UPDATE_SELECTED_BILLING_COUNTRY';
const UPDATE_SELECTED_COUNTRIES_FROM_INSTORE =
  'UPDATE_SELECTED_COUNTRIES_FROM_INSTORE';
const UPDATE_SELECTED_PREVIOUS_ORDER = 'UPDATE_SELECTED_PREVIOUS_ORDER';
const UPDATE_SELECTED_PREVIOUS_ORDER_DETAILS =
  'UPDATE_SELECTED_PREVIOUS_ORDER_DETAILS';
const UPDATE_INSTORE_PAYMENT_SUMMARY = 'UPDATE_INSTORE_PAYMENT_SUMMARY';
const UPDATE_SELECTED_NUMBER_OF_INSTALLMENTS =
  'UPDATE_SELECTED_NUMBER_OF_INSTALLMENTS';
const UPDATE_SELECTED_PRODUCT_VARIANT = 'UPDATE_SELECTED_PRODUCT_VARIANT';
const UPDATE_IS_MANUAL_SIGNING = 'UPDATE_IS_MANUAL_SIGNING';
const UPDATE_IS_PRICING_STRUCTURE_CODE_REQUIRED =
  'UPDATE_IS_PRICING_STRUCTURE_CODE_REQUIRED';
const UPDATE_ORDER_DISCOUNT_AMOUNT = 'UPDATE_ORDER_DISCOUNT_AMOUNT';
const UPDATE_DEPOSIT_OVERRIDE_AMOUNT = 'UPDATE_DEPOSIT_OVERRIDE_AMOUNT';
const UPDATE_PREFILL_FORM_COUNT = 'UPDATE_PREFILL_FORM_COUNT';

export const {
  updateAmount,
  updateAvailableProducts,
  updateDepositOverrideAmount,
  updateInstorePaymentSummary,
  updateIsManualSigning,
  updateIsPricingStructureCodeRequired,
  updateOrderDiscountAmount,
  updateOrderProfile,
  updateOrderProfiles,
  updatePrefillFormCount,
  updatePreviousOrders,
  updatePricingStructureCode,
  updateProductDetails,
  updateProducts,
  updateProductSku,
  updateSelectedBillingCountry,
  updateSelectedCountriesFromInstore,
  updateSelectedNumberOfInstallments,
  updateSelectedPreviousOrder,
  updateSelectedPreviousOrderDetails,
  updateSelectedProductVariant,
  updateSelectedShippingCountry,
} = createActions({
  [UPDATE_AMOUNT]: amount => ({ amount }),
  [UPDATE_AVAILABLE_PRODUCTS]: availableProducts => ({ availableProducts }),
  [UPDATE_ORDER_PROFILE]: orderProfile => ({ orderProfile }),
  [UPDATE_ORDER_PROFILES]: orderProfiles => ({ orderProfiles }),
  [UPDATE_PREFILL_FORM_COUNT]: prefillFormCount => ({ prefillFormCount }),
  [UPDATE_PREVIOUS_ORDERS]: previousOrders => ({ previousOrders }),
  [UPDATE_PRICING_STRUCTURE_CODE]: pricingStructureCode => ({
    pricingStructureCode,
  }),
  [UPDATE_PRODUCTS]: products => ({ products }),
  [UPDATE_PRODUCT_SKU]: productSku => ({ productSku }),
  [UPDATE_PRODUCT_DETAILS]: productDetails => ({ productDetails }),
  [UPDATE_SELECTED_SHIPPING_COUNTRY]: selectedShippingCountry => ({
    selectedShippingCountry,
  }),
  [UPDATE_SELECTED_BILLING_COUNTRY]: selectedBillingCountry => ({
    selectedBillingCountry,
  }),
  [UPDATE_SELECTED_COUNTRIES_FROM_INSTORE]: countriesFromInstore => ({
    countriesFromInstore,
  }),
  [UPDATE_SELECTED_PREVIOUS_ORDER]: selectedPreviousOrder => ({
    selectedPreviousOrder,
  }),
  [UPDATE_SELECTED_PREVIOUS_ORDER_DETAILS]: selectedPreviousOrderDetails => ({
    selectedPreviousOrderDetails,
  }),
  [UPDATE_INSTORE_PAYMENT_SUMMARY]: paymentSummary => ({ paymentSummary }),
  [UPDATE_SELECTED_NUMBER_OF_INSTALLMENTS]: selectedNumberOfInstallments => ({
    selectedNumberOfInstallments,
  }),
  [UPDATE_SELECTED_PRODUCT_VARIANT]: selectedProductVariant => ({
    selectedProductVariant,
  }),
  [UPDATE_IS_MANUAL_SIGNING]: isManualSigning => ({
    isManualSigning,
  }),
  [UPDATE_IS_PRICING_STRUCTURE_CODE_REQUIRED]:
    isPricingStructureCodeRequired => ({
      isPricingStructureCodeRequired,
    }),
  [UPDATE_ORDER_DISCOUNT_AMOUNT]: orderDiscountAmount => ({
    orderDiscountAmount,
  }),
  [UPDATE_DEPOSIT_OVERRIDE_AMOUNT]: depositOverrideAmount => ({
    depositOverrideAmount,
  }),
});

export const loadPaymentSummary = actionCreator(
  'loadPaymentPlan',
  () =>
    async (
      dispatch: ThunkDispatch<IRootState, void, AnyAction>,
      getState: () => IRootState,
    ) => {
      try {
        const state = getState();
        const {
          amount,
          depositOverrideAmount,
          multiProductAggregate,
          orderDiscountAmount,
          singleProductArray,
          sku,
          totalAmount,
          totalResidualValue,
        } = getPaymentInfo(state);

        if (multiProductAggregate.products.length && amount.amount) {
          throw new Error(
            'We should never have an amount and multiple products',
          );
        }

        const orderProfileUuid = getOrderProfileUuid(state);
        const numberOfInstallments = getSelectedNumberOfInstallments(state);
        const paymentInfoValid = getIsPaymentInfoValid(
          state,
          totalAmount,
          totalResidualValue,
        );
        const pricingStructureCode = getPricingStructureCode(state);
        const isPricingStructureCodeRequired =
          getIsPricingStructureCodeRequired(state);

        const shouldReloadForPricingStructureCode =
          isPricingStructureCodeRequired && !pricingStructureCode;
        if (
          !orderProfileUuid ||
          !numberOfInstallments ||
          !paymentInfoValid ||
          shouldReloadForPricingStructureCode
        ) {
          dispatch(updateInstorePaymentSummary({}));
          dispatch(resetPaymentPlan());
          return;
        }

        dispatch(setLoadingState(true));
        dispatch(resetErrorState());

        const maxInstallment = Math.max(...numberOfInstallments);

        const products = sku
          ? singleProductArray
          : multiProductAggregate.products;

        const paymentSummary = await instoreService.loadPaymentSummary(
          totalAmount,
          maxInstallment,
          orderProfileUuid,
          pricingStructureCode,
          products,
          orderDiscountAmount,
          depositOverrideAmount,
        );

        const paymentPlanSummary = getSummaryData(paymentSummary);

        dispatch(updateInstorePaymentSummary(paymentSummary));
        dispatch(updatePaymentPlan(paymentPlanSummary));
      } catch (error) {
        dispatch(setErrorState(error));
      } finally {
        dispatch(setLoadingState(false));
      }
    },
);

const debounceLoadOrderProfiles = debounce(
  async (dispatch: AppDispatch, getState: () => IRootState) => {
    try {
      const state = getState();
      const {
        instore: { isManualSigning, pricingStructureCode },
      } = state;

      const {
        amount,
        multiProductAggregate,
        orderDiscountAmount,
        singleProductArray,
        sku,
        totalAmount,
      } = getPaymentInfo(state);

      if (multiProductAggregate.products.length && amount.amount) {
        throw new Error('We should never have an amount and multiple products');
      }

      const isSelectedSkuValid = Boolean(sku);
      const selectedProducts: IProductObject[] = isSelectedSkuValid
        ? singleProductArray
        : multiProductAggregate.products;

      if (
        !selectedProducts.every(product => product.price) ||
        !totalAmount?.amount ||
        !totalAmount?.currency
      ) {
        return;
      }

      dispatch(setLoadingState(true));

      const orderProfiles = await instoreService.loadAvailableOrderProfiles(
        totalAmount,
        selectedProducts,
        isManualSigning,
        orderDiscountAmount,
        pricingStructureCode,
      );

      dispatch(updateOrderProfiles(orderProfiles));

      const orderProfileUuid = getOrderProfileUuid(state);

      if (
        orderProfiles.length &&
        orderProfiles?.find(
          orderProfile => orderProfile.uuid === orderProfileUuid,
        )
      ) {
        await dispatch(loadPaymentSummary());
      }
    } catch (error) {
      dispatch(setErrorState(error));
    } finally {
      dispatch(setLoadingState(false));
    }
  },
  500,
);

export const loadOrderProfiles = actionCreator(
  'loadOrderProfiles',
  () => (dispatch: AppDispatch, getState: () => IRootState) =>
    debounceLoadOrderProfiles(dispatch, getState),
);

export const loadProductDetails = actionCreator(
  'loadProductDetails',
  () => async (dispatch: AppDispatch, getState: () => IRootState) => {
    try {
      const state = getState();
      const productSku = getProductSku(state);
      if (!productSku) {
        return;
      }
      dispatch(setLoadingState(true));
      dispatch(resetErrorState());
      const productDetails = await instoreService.getMerchantProduct(
        productSku,
      );
      dispatch(updateProductDetails(productDetails));
      dispatch(loadOrderProfiles());
    } catch (error) {
      dispatch(setErrorState(error));
    } finally {
      dispatch(setLoadingState(false));
    }
  },
);

export const loadPreviousOrders = actionCreator(
  'loadPreviousOrders',
  () => async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoadingState(true));
      dispatch(resetErrorState());
      const orders = await instoreService.getMerchantUserOrders();
      dispatch(updatePreviousOrders(orders));
    } catch (error) {
      dispatch(setErrorState(error));
    } finally {
      dispatch(setLoadingState(false));
    }
  },
);

export const loadSelectedPreviousOrderDetails = actionCreator(
  'loadSelectedPreviousOrderDetails',
  (uuid: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoadingState(true));
      dispatch(resetErrorState());
      const orderDetails = await instoreService.getMerchantUserOrderDetails(
        uuid,
      );
      dispatch(updateSelectedPreviousOrderDetails(orderDetails));
    } catch (error) {
      dispatch(setErrorState(error));
    } finally {
      dispatch(setLoadingState(false));
    }
  },
);

export const setAmount = actionCreator(
  'setAmount',
  (amount: IAmount) => async (dispatch: AppDispatch) => {
    dispatch(updateAmount(amount));
    dispatch(loadOrderProfiles());
  },
);

export const setSelectedNumberOfInstallments = actionCreator(
  'setSelectedNumberOfInstallments',
  (numberOfInstallments: number[]) =>
    async (dispatch: AppDispatch, getState: () => IRootState) => {
      const state = getState();
      const currentInstallments = getSelectedNumberOfInstallments(state) ?? [];
      const newInstallments = numberOfInstallments ?? [];

      const installmentsAreDifferent =
        currentInstallments.length !== newInstallments.length ||
        !currentInstallments.every(element =>
          newInstallments.includes(element),
        );

      if (installmentsAreDifferent) {
        dispatch(updateSelectedNumberOfInstallments(numberOfInstallments));
        await dispatch(loadPaymentSummary());
      }
    },
);

export const resetOrderProfile = actionCreator(
  'resetOrderProfile',
  () => async (dispatch: AppDispatch) => {
    dispatch(updateSelectedNumberOfInstallments(null));
    dispatch(updateSelectedProductVariant(null));
    dispatch(updateOrderProfile(null));
    dispatch(updateInstorePaymentSummary(null));
  },
);

export const setAvailableProducts = actionCreator(
  'setAvailableProducts',
  (availableProducts: IProduct[]) => (dispatch: AppDispatch) => {
    dispatch(updateAvailableProducts(availableProducts));
  },
);

export const setProductSku = actionCreator(
  'setProductSku',
  (sku: string) => (dispatch: AppDispatch) => {
    dispatch(updateProductSku(sku));
    dispatch(updateSelectedProductVariant(null));
    dispatch(loadProductDetails());
  },
);

export const setOrderProfile = actionCreator(
  'setOrderProfile',
  (orderProfile: IOrderProfile) => (dispatch: AppDispatch) => {
    dispatch(updateOrderProfile(orderProfile));
  },
);

export const setSelectedProductVariant = actionCreator(
  'setSelectedProductVariant',
  (productVariant: IVariant) => (dispatch: AppDispatch) => {
    dispatch(updateSelectedProductVariant(productVariant));
  },
);

export const setSelectedShippingCountry = actionCreator(
  'setSelectedShippingCountry',
  (selectedShippingCountry: string) => (dispatch: AppDispatch) => {
    dispatch(updateSelectedShippingCountry(selectedShippingCountry));
  },
);

export const setSelectedBillingCountry = actionCreator(
  'setSelectedBillingCountry',
  (selectedBillingCountry: string) => (dispatch: AppDispatch) => {
    dispatch(updateSelectedBillingCountry(selectedBillingCountry));
  },
);

export const setCountriesFromInstore = actionCreator(
  'setCountriesFromInstore',
  (countriesFromInstore: string[]) => (dispatch: AppDispatch) => {
    dispatch(updateSelectedCountriesFromInstore(countriesFromInstore));
  },
);

export const setProducts = actionCreator(
  'setProducts',
  (products: IProduct[]) => async (dispatch: AppDispatch) => {
    dispatch(updateProducts(products));
    dispatch(loadOrderProfiles());
  },
);

export const setIsManualSigning = actionCreator(
  'setIsManualSigning',
  (isManualSigning: boolean | null) => async (dispatch: AppDispatch) => {
    dispatch(updateIsManualSigning(isManualSigning));
    dispatch(loadOrderProfiles());
  },
);

export const setPricingStructureCode = actionCreator(
  'setPricingStructureCode',
  (code: string) => async (dispatch: AppDispatch) => {
    dispatch(updatePricingStructureCode(code));
    await dispatch(loadPaymentSummary());
  },
);

export const setOrderDiscountAmount = actionCreator(
  'setOrderDiscountAmount',
  (orderDiscountAmount: IAmount | null) => async (dispatch: AppDispatch) => {
    dispatch(updateOrderDiscountAmount(orderDiscountAmount));
    dispatch(loadOrderProfiles());
  },
);

export const setDepositOverrideAmount = actionCreator(
  'setDepositOverrideAmount',
  (depositOverrideAmount: IAmount | null) => async (dispatch: AppDispatch) => {
    dispatch(updateDepositOverrideAmount(depositOverrideAmount));
    dispatch(loadOrderProfiles());
  },
);

const updateStateField = <T>(
  state: IInstoreState,
  payload: Record<string, T>,
  fieldName: string,
) => ({
  ...state,
  [fieldName]: payload[fieldName],
});

export default handleActions(
  {
    [UPDATE_PRODUCTS]: (
      state: IInstoreState,
      action: PayloadAction<{ products: IProductObject[] }>,
    ) => updateStateField(state, action.payload, 'products'),
    [UPDATE_AMOUNT]: (
      state: IInstoreState,
      action: PayloadAction<{ amount: IAmount }>,
    ) => updateStateField(state, action.payload, 'amount'),
    [UPDATE_AVAILABLE_PRODUCTS]: (
      state: IInstoreState,
      action: PayloadAction<{ availableProducts: IProduct[] }>,
    ) => updateStateField(state, action.payload, 'availableProducts'),
    [UPDATE_IS_MANUAL_SIGNING]: (
      state: IInstoreState,
      action: PayloadAction<{ isManualSigning: boolean | null }>,
    ) => updateStateField(state, action.payload, 'isManualSigning'),
    [UPDATE_IS_PRICING_STRUCTURE_CODE_REQUIRED]: (
      state: IInstoreState,
      action: PayloadAction<{ isPricingStructureCodeRequired: boolean }>,
    ) =>
      updateStateField(state, action.payload, 'isPricingStructureCodeRequired'),
    [UPDATE_ORDER_PROFILE]: (
      state: IInstoreState,
      action: PayloadAction<{ orderProfile?: IOrderProfile | null }>,
    ) => updateStateField(state, action.payload, 'orderProfile'),
    [UPDATE_ORDER_PROFILES]: (
      state: IInstoreState,
      action: PayloadAction<{ orderProfiles: IOrderProfile[] | null }>,
    ) => updateStateField(state, action.payload, 'orderProfiles'),
    [UPDATE_PREFILL_FORM_COUNT]: (state: IInstoreState) =>
      incrementStateField({ state, fieldName: 'prefillFormCount' }),
    [UPDATE_PREVIOUS_ORDERS]: (
      state: IInstoreState,
      action: PayloadAction<{ previousOrders: IPreviousOrder[] }>,
    ) => updateStateField(state, action.payload, 'previousOrders'),
    [UPDATE_PRICING_STRUCTURE_CODE]: (
      state: IInstoreState,
      action: PayloadAction<{ pricingStructureCode: string | null }>,
    ) => updateStateField(state, action.payload, 'pricingStructureCode'),
    [UPDATE_PRODUCT_SKU]: (
      state: IInstoreState,
      action: PayloadAction<{ productSku: string }>,
    ) => updateStateField(state, action.payload, 'productSku'),
    [UPDATE_PRODUCT_DETAILS]: (
      state: IInstoreState,
      action: PayloadAction<{ productDetails: IProduct | null }>,
    ) => updateStateField(state, action.payload, 'productDetails'),
    [UPDATE_SELECTED_SHIPPING_COUNTRY]: (
      state: IInstoreState,
      action: PayloadAction<{ selectedShippingCountry: string }>,
    ) => updateStateField(state, action.payload, 'selectedShippingCountry'),
    [UPDATE_SELECTED_BILLING_COUNTRY]: (
      state: IInstoreState,
      action: PayloadAction<{ selectedBillingCountry: string }>,
    ) => updateStateField(state, action.payload, 'selectedBillingCountry'),
    [UPDATE_SELECTED_COUNTRIES_FROM_INSTORE]: (
      state: IInstoreState,
      action: PayloadAction<{ countriesFromInstore: string[] }>,
    ) => updateStateField(state, action.payload, 'countriesFromInstore'),
    [UPDATE_SELECTED_PREVIOUS_ORDER]: (
      state: IInstoreState,
      action: PayloadAction<{ selectedPreviousOrder: string | null }>,
    ) => updateStateField(state, action.payload, 'selectedPreviousOrder'),
    [UPDATE_SELECTED_PREVIOUS_ORDER_DETAILS]: (
      state: IInstoreState,
      action: PayloadAction<{
        selectedPreviousOrderDetails: IPreviousOrderDetails | null;
      }>,
    ) =>
      updateStateField(state, action.payload, 'selectedPreviousOrderDetails'),
    [UPDATE_SELECTED_PRODUCT_VARIANT]: (
      state: IInstoreState,
      action: PayloadAction<{ selectedProductVariant: IVariant | null }>,
    ) => updateStateField(state, action.payload, 'selectedProductVariant'),
    [UPDATE_INSTORE_PAYMENT_SUMMARY]: (
      state: IInstoreState,
      action: PayloadAction<{ paymentSummary: ILoadPaymentSummary | null }>,
    ) => updateStateField(state, action.payload, 'paymentSummary'),
    [UPDATE_SELECTED_NUMBER_OF_INSTALLMENTS]: (
      state: IInstoreState,
      action: PayloadAction<{ selectedNumberOfInstallments: number[] | null }>,
    ) =>
      updateStateField(state, action.payload, 'selectedNumberOfInstallments'),
    [UPDATE_ORDER_DISCOUNT_AMOUNT]: (
      state: IInstoreState,
      action: PayloadAction<{ orderDiscountAmount: IAmount | null }>,
    ) => updateStateField(state, action.payload, 'orderDiscountAmount'),
    [UPDATE_DEPOSIT_OVERRIDE_AMOUNT]: (
      state: IInstoreState,
      action: PayloadAction<{ depositOverrideAmount: IAmount | null }>,
    ) => updateStateField(state, action.payload, 'depositOverrideAmount'),
  },
  initialState,
);
