import { useEffect, useState } from 'react';
import i18n from 'i18next';

export const useI18nLanguageChange = () => {
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChange = (lang: string) => {
      setLanguage(lang);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  return { language };
};
