import { IScheduleRow } from '@heidi-pay/heidi-component-library/components/PaymentPlanTable';
import { IPaymentSchedule } from '@redux/payment/types';
import { IUseFormatter } from '@utils/formatters/types';

export interface IGetScheduleRows {
  currency: string;
  enableSameInstallmentNumberForDownPayment?: boolean;
  formatters: IUseFormatter;
  payNowAmount?: string;
  schedule: IPaymentSchedule[];
  selectedNumberOfInstallments: number;
}

export const getScheduleRows = ({
  currency,
  enableSameInstallmentNumberForDownPayment = false,
  formatters,
  payNowAmount,
  schedule,
  selectedNumberOfInstallments,
}: IGetScheduleRows) => {
  const shouldIncludeDownPayment =
    enableSameInstallmentNumberForDownPayment &&
    payNowAmount &&
    schedule?.length !== selectedNumberOfInstallments;

  const adaptedSchedule = shouldIncludeDownPayment
    ? [
        {
          amount: payNowAmount,
          date: new Date().toDateString(),
        } as IPaymentSchedule,
        ...schedule,
      ]
    : schedule;

  return adaptedSchedule.map(
    item =>
      ({
        amount: formatters.formatAmountWithCurrency(item.amount, currency),
        date: formatters.formatDate({ date: item.date }),
      } as IScheduleRow),
  );
};
