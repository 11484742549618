import * as yup from 'yup';
import { ResidencePermit, IdentityCheckMethod } from '../enums';
import {
  dateValidationTests,
  isManualVerification,
  isNonSwiss,
  isPermitB,
  validationKey,
} from '../utils/helpers';

export const getValidationSchema = () =>
  yup.object().shape({
    selectedSwissManualIdentityVerification: yup
      .string()
      .required(validationKey.swissManualIdentityVerificationRequired),

    selectedNationality: yup
      .string()
      .when('selectedSwissManualIdentityVerification', {
        is: isManualVerification,
        then: schema =>
          schema.required(validationKey.nationalityPickerRequired),
        otherwise: schema => schema.notRequired(),
      }),

    selectedResidencePermit: yup
      .string()
      .when(
        ['selectedSwissManualIdentityVerification', 'selectedNationality'],
        {
          is: (verificationMethod: IdentityCheckMethod, nationality: string) =>
            isManualVerification(verificationMethod) && isNonSwiss(nationality),
          then: schema =>
            schema
              .oneOf(
                [ResidencePermit.PermitB, ResidencePermit.PermitC],
                validationKey.residencePermitOnlyBAndCAccepted,
              )
              .required(validationKey.residencePermitRequired),
          otherwise: schema =>
            schema
              .oneOf(
                [ResidencePermit.PermitB, ResidencePermit.PermitC, ''],
                validationKey.residencePermitOnlyBAndCAccepted,
              )
              .notRequired(),
        },
      ),

    selectedDateOfEntry: yup
      .mixed()
      .when(
        ['selectedSwissManualIdentityVerification', 'selectedResidencePermit'],
        {
          is: (
            verificationMethod: IdentityCheckMethod,
            residencePermit: ResidencePermit,
          ) =>
            isManualVerification(verificationMethod) &&
            isPermitB(residencePermit),
          then: schema =>
            schema
              .required(validationKey.dateOfEntryRequired)
              .test(dateValidationTests.basic(false))
              .test(dateValidationTests.minOneYearOld(false)),
          otherwise: schema =>
            schema
              .notRequired()
              .test(dateValidationTests.basic(true))
              .test(dateValidationTests.minOneYearOld(true)),
        },
      ),

    selectedConfirmation: yup
      .boolean()
      .when('selectedSwissManualIdentityVerification', {
        is: isManualVerification,
        then: schema =>
          schema.oneOf([true], validationKey.confirmationCheckboxRequired),
        otherwise: schema => schema.notRequired(),
      }),
  });
