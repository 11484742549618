import { call, HttpMethod, setToken } from '@utils';

export interface IOtcExchangeResponse {
  onboarding_widget_token: string;
  token: string;
}

export async function exchangeOtcToken(otc: string) {
  const response = await call<IOtcExchangeResponse>(
    '/api/infocert/identity/mobile/otc-exchange',
    {
      body: { otc },
      method: HttpMethod.Post,
    },
  );

  setToken(response.token);

  return response.onboarding_widget_token;
}

export async function setIdentityVerificationSessionComplete() {
  call('/api/infocert/identity/mobile/complete', {
    method: HttpMethod.Post,
  });
}
