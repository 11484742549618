import { Locale } from 'date-fns';
import de from 'date-fns/locale/de';
import enGBLocale from 'date-fns/locale/en-GB';
import frCHLocale from 'date-fns/locale/fr-CH';
import it from 'date-fns/locale/it';

interface ITranslationConstants {
  companyLegalName: string;
  companyName: string;
  italyInformativaUri: string;
  supportEmailAddress: string;
  swiss3DSecureUriDe: string;
  swiss3DSecureUriEn: string;
  swiss3DSecureUriFr: string;
  swiss3DSecureUriIt: string;
  swissPrivacyPolicyUriDe: string;
  swissPrivacyPolicyUriEn: string;
  swissPrivacyPolicyUriFr: string;
  swissPrivacyPolicyUriIt: string;
}

export const heyLightTranslationConstants: ITranslationConstants = {
  companyLegalName: 'HeyLight AG',
  companyName: 'HeyLight',
  italyInformativaUri:
    'https://storage.googleapis.com/mediobanca-public-docs/CO087FB_ON_LINE_09_24_HEYLIGHT.pdf',
  supportEmailAddress: 'heylight-support@heidipay.com',
  swiss3DSecureUriDe: 'https://heylight.com/ch/de/3d-secure-verification-de',
  swiss3DSecureUriEn: 'https://heylight.com/ch/en/3d-secure-verification-en',
  swiss3DSecureUriFr: 'https://heylight.com/ch/fr/3d-secure-verification-fr',
  swiss3DSecureUriIt: 'https://heylight.com/ch/it/3d-secure-verification-it',
  swissPrivacyPolicyUriDe: 'https://heylight.com/ch/de/privacy-policy-ch',
  swissPrivacyPolicyUriEn: 'https://heylight.com/ch/en/privacy-policy-ch',
  swissPrivacyPolicyUriFr: 'https://heylight.com/ch/fr/privacy-policy-ch',
  swissPrivacyPolicyUriIt: 'https://heylight.com/ch/it/privacy-policy-ch',
};

export const getTranslationConstants = () => heyLightTranslationConstants;

export const localeMap: Record<string, Locale> = {
  'en-gb': enGBLocale,
  'fr-ch': frCHLocale,
  'it-ch': it,
  'de-ch': de,
};
