/* eslint-disable max-lines */
import * as yup from 'yup';
import {
  TextInputNumeric,
  TextInput,
} from '../../../../../transaction/components/heylight';
import { FieldNames, InstoreFormType } from '../enums';
import { InstoreProductCategoryForm } from '../InstoreProductCategoryForm';
import { InstoreProductForm } from '../InstoreProductForm';
import {
  ICategoryProduct,
  IDefaultFreeTextProduct,
  IDefaultProduct,
  IFormSettings,
  IGetFormSettingsOptions,
  IMobilezoneProduct,
} from './types';

const getCategoryForm = ({
  isIdentityFieldEnabled,
  isNameFieldEnabled,
  isQuantityFieldEnabled,
}: IGetFormSettingsOptions): IFormSettings<ICategoryProduct> => ({
  instoreProductForm: InstoreProductCategoryForm,
  defaultValues: {
    products: [
      {
        [FieldNames.AdditionalDetails]: '',
        [FieldNames.Price]: { amount: '', currency: '' },
        [FieldNames.ProductSku]: '',
        [FieldNames.ResidualValue]: '',
        ...(isNameFieldEnabled
          ? {
              [FieldNames.ProductName]: '',
            }
          : {}),
        ...(isIdentityFieldEnabled
          ? {
              [FieldNames.ProductIdentity]: '',
            }
          : {}),
        ...(isQuantityFieldEnabled
          ? {
              [FieldNames.Quantity]: '',
            }
          : {}),
      },
    ],
  },
  validationSchema: {
    [FieldNames.Price]: TextInputNumeric.validation(FieldNames.Price, {
      isMonetaryObject: true,
    }),
    [FieldNames.ProductSku]: yup
      .string()
      .required('fields.categorySelect.required'),
    ...(isNameFieldEnabled
      ? {
          [FieldNames.ProductName]: yup.string().nullable(),
        }
      : {}),
    ...(isIdentityFieldEnabled
      ? {
          [FieldNames.ProductIdentity]: yup.string().nullable(),
        }
      : {}),
    ...(isQuantityFieldEnabled
      ? {
          [FieldNames.Quantity]: TextInputNumeric.validation(
            FieldNames.Quantity,
            {},
          ),
        }
      : {}),
  },
});

const getMobilezoneTradeInForm = (
  currency: string,
): IFormSettings<IMobilezoneProduct> => ({
  defaultValues: {
    products: [
      {
        [FieldNames.AdditionalDetails]: '',
        [FieldNames.Discount]: '',
        [FieldNames.InstoreProductSearchTextInput]: '',
        [FieldNames.ProductSku]: '',
        [FieldNames.ProductIdentity]: '',
        [FieldNames.ProductName]: '',
        [FieldNames.ProductVariant]: '',
        [FieldNames.Price]: { amount: '', currency },
      },
    ],
  },
  validationSchema: {
    [FieldNames.Discount]: yup.string().nullable(),
    [FieldNames.InstoreProductSearchTextInput]: TextInput.validation(
      FieldNames.InstoreProductSearchTextInput,
      {},
    ),
    [FieldNames.Price]: TextInputNumeric.validation(FieldNames.Price, {
      isMonetaryObject: true,
    }),
    [FieldNames.ProductName]: yup
      .string()
      .required(`fields.textInput.${FieldNames.ProductName}.required`),
    [FieldNames.ProductIdentity]: yup
      .string()
      .required(`fields.textInput.${FieldNames.ProductIdentity}.required`),
    [FieldNames.ProductVariant]: yup.string().nullable(),
  },
});

const getFreeTextDefaultForm = (
  currency: string,
): IFormSettings<IDefaultFreeTextProduct> => ({
  defaultValues: {
    products: [
      {
        [FieldNames.Price]: { amount: '', currency },
        [FieldNames.ProductName]: '',
        [FieldNames.Discount]: '',
        [FieldNames.ProductSku]: '',
      },
    ],
  },
  validationSchema: {
    [FieldNames.Discount]: yup.string().nullable(),
    [FieldNames.Price]: TextInputNumeric.validation(FieldNames.Price, {
      isMonetaryObject: true,
    }),
    [FieldNames.ProductName]: yup
      .string()
      .required(`fields.textInput.${FieldNames.ProductName}.required`),
    [FieldNames.ProductSku]: yup.string().nullable(),
  },
});

const getDefaultForm = ({
  isIdentityFieldEnabled,
  isNameFieldEnabled,
  isQuantityFieldEnabled,
}: IGetFormSettingsOptions): IFormSettings<IDefaultProduct> => ({
  instoreProductForm: InstoreProductForm,
  defaultValues: {
    products: [
      {
        [FieldNames.Discount]: '',
        [FieldNames.Price]: '',
        [FieldNames.ProductSku]: '',
        [FieldNames.AdditionalDetails]: '',
        ...(isNameFieldEnabled
          ? {
              [FieldNames.ProductName]: '',
            }
          : {}),
        ...(isIdentityFieldEnabled
          ? {
              [FieldNames.ProductIdentity]: '',
            }
          : {}),
        ...(isQuantityFieldEnabled
          ? {
              [FieldNames.Quantity]: '',
            }
          : {}),
      },
    ],
  },
  validationSchema: {
    [FieldNames.Discount]: TextInputNumeric.validation(FieldNames.Discount, {
      optional: true,
    }),
    [FieldNames.Price]: TextInputNumeric.validation(FieldNames.Price, {}),
    ...(isIdentityFieldEnabled
      ? {
          [FieldNames.ProductName]: yup.string().nullable(),
        }
      : {}),
    [FieldNames.ProductSku]: TextInput.validation(FieldNames.ProductSku, {}),
    ...(isIdentityFieldEnabled
      ? {
          [FieldNames.ProductIdentity]: yup.string().nullable(),
        }
      : {}),
    ...(isQuantityFieldEnabled
      ? {
          [FieldNames.Quantity]: TextInputNumeric.validation(
            FieldNames.Quantity,
            {},
          ),
        }
      : {}),
  },
});

export const getFormSettings = (
  formType: InstoreFormType,
  formOptions: IGetFormSettingsOptions = {
    currency: '',
    isIdentityFieldEnabled: false,
    isNameFieldEnabled: true,
    isQuantityFieldEnabled: false,
  },
) => {
  if (formType === InstoreFormType.MobilezoneTradeIn) {
    return getMobilezoneTradeInForm(formOptions?.currency ?? '');
  }

  if (formType === InstoreFormType.DefaultFreeText) {
    return getFreeTextDefaultForm(formOptions?.currency ?? '');
  }

  if (formType === InstoreFormType.Category) {
    return getCategoryForm(formOptions);
  }

  return getDefaultForm(formOptions);
};
