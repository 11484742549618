import { ValueOf } from '@heidi-pay/heidi-common-fe/types';
import { call, HttpMethod } from '../../utils/http';

export const VerificationStatus = {
  verified: 'verified',
  invalid: 'invalid',
} as const;

export const VerificationCodeTypes = {
  signContract: 'sign_contract',
  phoneVerification: 'phone_verification',
} as const;

export type VerificationStatusType = ValueOf<typeof VerificationStatus>;
export type VerificationCodeType = ValueOf<typeof VerificationCodeTypes>;

interface IVerificationResponse {
  status?: VerificationStatusType;
}

interface IVerificationResult {
  isValid: boolean;
}

export async function verifyCode(code: string): Promise<IVerificationResult> {
  const response: IVerificationResponse =
    (await call('/api/checkout-client/text/verify/', {
      method: HttpMethod.Post,
      body: JSON.stringify({
        verification_code: code,
      }),
    })) || {};

  return { isValid: response.status === VerificationStatus.verified };
}

export async function generateCode(
  verificationCodeType: VerificationCodeType = VerificationCodeTypes.phoneVerification,
): Promise<{ message: string; status: string }> {
  return call('/api/checkout-client/text/resend/', {
    method: HttpMethod.Post,
    body: JSON.stringify({
      verification_code_type: verificationCodeType,
    }),
  });
}
