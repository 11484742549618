export enum Environment {
  CL = 'CL',
  PR = 'PR',
  TS = 'TS',
}

export enum EventType {
  DeviceBinding = 'deviceBinding',
  Finished = 'finished',
  TaskClose = 'taskClose',
  TaskCompleted = 'taskCompleted',
  TaskReady = 'taskReady',
  WidgetClose = 'widgetClose',
  WidgetError = 'widgetError',
  WidgetStart = 'widgetStart',
}
