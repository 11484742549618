import { ChangeEvent, ReactNode, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import {
  Camera,
  Upload,
} from '@heidi-pay/heidi-component-library/components/Icons';
import {
  BaseColour,
  PaletteColorOptions,
} from '@heidi-pay/heidi-component-library/enums';
import { Box, styled, Typography } from '@mui/material';
import {
  ExperianItalyAnalyticsEventType,
  sendExperianItalyAnalytics,
} from '../../../../services/ExperianItalyAnalyticsService';
import { FileSelector } from '../components/FileSelector';
import { Headline } from '../components/Headline';
import { FileSelectionType, ActionName } from '../enums';
import { IProcessContainerProps } from './types';

export const baseTranslationKey =
  'fields.mitekDocumentOcrContainer.selectProcess';
export const baseButtonTranslationKey =
  'fields.mitekDocumentOcrContainer.buttons';

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'flex-start',
  padding: '9px 11px',
  textAlign: 'left',
  justifyContent: 'center',
});

const IconWrapper = styled(Box)({
  flexShrink: 0,
  height: 30,
  width: 30,
  marginRight: 16,
});

interface IToggleButtonProps {
  children?: ReactNode;
  iconComponent: ReactNode;
  isFileSelect?: boolean;
  label: string;
  onClick?: () => void;
  subLabel: string;
}

const ToggleButton = ({
  children,
  iconComponent,
  isFileSelect = false,
  label,
  onClick,
  subLabel,
}: IToggleButtonProps) => (
  <BaseButton
    color={PaletteColorOptions.ToggleUnselected}
    onClick={onClick}
    sx={{ minWidth: '100%' }}
    tabIndex={-1}
    variant="contained"
    {...(isFileSelect ? { component: 'label', role: undefined } : {})}
  >
    <IconWrapper>{iconComponent}</IconWrapper>
    <ContentWrapper>
      <Typography
        color={PaletteColorOptions.ToggleUnselected}
        fontSize="16px"
        fontWeight="bold"
      >
        {label}
      </Typography>
      <Typography color={BaseColour.Grey1} variant="caption">
        {subLabel}
      </Typography>
    </ContentWrapper>
    {children}
  </BaseButton>
);

export interface IToggleButtonsProps {
  onCaptureButtonClick: () => void;
  onUploadButtonClick: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const ToggleButtons = ({
  filesData,
  onAddFile,
  onRegisterGlobalEventListeners,
  onSetCurrentAction,
  onSetError,
  onSetFileSelectionType,
}: IProcessContainerProps) => {
  const fileSelectorRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const triggerInitialEvents = useCallback(
    (
      analyticsEventType: ExperianItalyAnalyticsEventType,
      fileSelectionType: FileSelectionType,
    ) => {
      sendExperianItalyAnalytics(analyticsEventType);
      onSetError('');
      onRegisterGlobalEventListeners();
      onSetFileSelectionType(fileSelectionType);
    },
    [onRegisterGlobalEventListeners, onSetError, onSetFileSelectionType],
  );

  const handleOnBeforeFileSelection = useCallback(
    () =>
      triggerInitialEvents(
        'INITIALISED_FILE_SELECTION',
        FileSelectionType.FileSystem,
      ),
    [triggerInitialEvents],
  );

  const hadnleOnAfterFileSelection = useCallback(() => {
    if (fileSelectorRef.current) {
      fileSelectorRef.current.value = '';
    }
  }, []);

  const handleOnFileData = useCallback(
    (fileData: string) => {
      onAddFile(fileData);
      onSetCurrentAction(ActionName.ScanResult);
    },
    [onAddFile, onSetCurrentAction],
  );

  const handleUserAutoCaptureClick = useCallback(() => {
    triggerInitialEvents(
      'INITIALISED_AUTOCAPTURE',
      FileSelectionType.ImageCapture,
    );
    onSetCurrentAction(ActionName.LoadingAutocapture);
  }, [onSetCurrentAction, triggerInitialEvents]);

  return (
    <>
      <Headline
        subtitleTranslationKey={`${baseTranslationKey}.subtitle`}
        titleTranslationKey={`${baseTranslationKey}.title`}
      />

      <ToggleButton
        iconComponent={<Upload height="25px" width="19px" />}
        isFileSelect={true}
        label={t(`${baseButtonTranslationKey}.selectFileButton.label`)}
        subLabel={t(`${baseButtonTranslationKey}.selectFileButton.subLabel`)}
      >
        <FileSelector
          filesData={filesData}
          inputRef={fileSelectorRef}
          isLabeledExternally={true}
          onAfterFileSelection={hadnleOnAfterFileSelection}
          onBeforeFileSelection={handleOnBeforeFileSelection}
          onFileData={handleOnFileData}
        />
      </ToggleButton>
      <ToggleButton
        iconComponent={<Camera height="25px" width="31px" />}
        label={t(`${baseButtonTranslationKey}.captureImageButton.label`)}
        onClick={handleUserAutoCaptureClick}
        subLabel={t(`${baseButtonTranslationKey}.captureImageButton.subLabel`)}
      />
    </>
  );
};
