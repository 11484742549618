import { IField } from '@appTypes';
import { call, HttpMethod } from '../../utils/http';

const POST_TIMEOUT_MS = 300000;

export interface IActionContext {
  fields: IField[];
}

export interface IActionDetails {
  actionName: string;
  context: IActionContext;
}

export interface IActionProgress {
  currentStep: number;
  totalSteps: number;
}

export interface IActionMetadata {
  progress: IActionProgress;
}

export interface IActionResponse {
  action: IActionDetails;
  meta: IActionMetadata;
}

export async function getTransactionStep(
  actionName: string,
  params: Record<string, string> | null = null,
  disableRetry = false,
): Promise<IActionResponse> {
  const queryString = params ? new URLSearchParams(params).toString() : null;
  const searchParams = queryString ? `?${queryString}` : '';
  return call(
    `/api/checkout-client/action/${actionName || ''}${searchParams}`,
    { disableRetry },
  );
}

export async function updateTransactionStep(
  actionName: string,
  fields: Record<string, unknown>,
): Promise<IActionResponse> {
  return call(`/api/checkout-client/action/${actionName || ''}`, {
    method: HttpMethod.Post,
    body: JSON.stringify(fields),
    timeout: POST_TIMEOUT_MS,
    disableRetry: false,
  });
}
