import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { changeLanguage } from 'i18next';
import { InfocertOnboardingWidget } from '@components/InfocertOnboardingWidget';
import { setIsLoading } from '@redux';
import {
  exchangeOtcToken,
  setIdentityVerificationSessionComplete,
} from '../../services/identityVerification';
import { getWidgetParametersFromSearch } from './utils/getWidgetParametersFromSearch';

export const IdentityVerification = () => {
  const [widgetAccessToken, setWidgetAccessToken] = useState<string>();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const onComplete = useCallback(
    () => setIdentityVerificationSessionComplete,
    [],
  );

  const { environment, lang, mobileOtc } =
    getWidgetParametersFromSearch(search) ?? {};

  useEffect(() => {
    if (lang) {
      changeLanguage(lang.toLowerCase());
    }
  }, [lang]);

  useEffect(() => {
    if (mobileOtc) {
      exchangeOtcToken(mobileOtc)
        .then(token => setWidgetAccessToken(token))
        .finally(() => dispatch(setIsLoading(false)));
    }
  }, [dispatch, mobileOtc]);

  if (!environment || !lang || !mobileOtc) {
    window.location.replace('404');
    return null;
  }

  if (!widgetAccessToken) {
    return null;
  }

  return (
    <InfocertOnboardingWidget
      environment={environment}
      language={lang}
      onComplete={onComplete}
      token={widgetAccessToken}
    />
  );
};
