import React from 'react';
import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { DatePicker } from '@heidi-pay/heidi-component-library/components/DatePicker';
import { FormControl } from '@mui/material';
import { useTranslation } from '@hooks';
import { StackedLabel } from '@transaction/components/StackedLabel';
import { formatOptionalLabel } from '@utils/formatters';
import { localeMap } from '../../../../constants';
import { dateFormatting } from '../DateOfBirthInput/validation';

interface IDateOfEntryInput {
  error?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  name: string;
  onChange: (date: Nullable<Date>) => void;
  optional: boolean;
  value?: Nullable<Date>;
}

export const DateOfEntryInput = ({
  error = '',
  inputRef,
  name,
  onChange,
  optional,
  value,
}: IDateOfEntryInput) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const adapterLocale = localeMap[locale.replace('_', '-').toLowerCase()];
  const label = formatOptionalLabel({
    labelKey: 'fields.dateOfEntry.label',
    translate: t,
    optional,
  });
  const subLabel = formatOptionalLabel({
    labelKey: 'fields.dateOfEntry.subLabel',
    translate: t,
    optional,
  });

  return (
    <FormControl
      component="fieldset"
      error={Boolean(error)}
      fullWidth={true}
      size="small"
      sx={{ mt: '20px' }}
      variant="outlined"
    >
      <StackedLabel
        className="MuiFormLabel-external"
        label={label}
        name="dateOfEntryStackedLabel"
        tooltip="dateOfEntry"
      />
      <DatePicker
        adapterLocale={adapterLocale}
        cancelButtonLabel={t('fields.dateOfEntry.cancel')}
        complete={Boolean(value)}
        disableFuture={true}
        error={error ? t(error) : undefined}
        format="dd/MM/yyyy"
        inputRef={inputRef}
        label={subLabel}
        name={name}
        okButtonLabel={t('fields.dateOfEntry.confirm')}
        onChange={onChange}
        sx={{ marginTop: '7px', width: '100%' }}
        value={value ? new Date(value) : null}
      />
    </FormControl>
  );
};

DateOfEntryInput.formatting = dateFormatting;
