import { useSelector } from 'react-redux';
import { Grid, styled } from '@mui/material';
import { useTranslation } from '@hooks';
import { getProductDetails, getPaymentSummary } from '@instore/redux/selectors';
import { getPaymentPlan, getMonthlyPayments } from '@redux/payment';
import { useFormatters } from '../../../utils';
import { PaymentPlanTable } from '../PaymentPlanTable';
import { getPaymentPlanFields } from './utils/getPaymentPlanFields';

const TableContainer = styled(Grid)`
  padding-right: 2.625rem;
`;

export const InstorePaymentSummaryMobilezone = () => {
  const productDetails = useSelector(getProductDetails);
  const paymentSummary = useSelector(getPaymentSummary);
  const monthlyPayments = useSelector(getMonthlyPayments);
  const paymentPlan = useSelector(getPaymentPlan);
  const { t } = useTranslation();
  const formatters = useFormatters();

  const rows = getPaymentPlanFields({
    formatters,
    paymentPlan,
    monthlyPayments,
    paymentSummary,
    productDetails,
    translate: t,
  });

  if (!rows) {
    return null;
  }

  return (
    <TableContainer container={true}>
      <PaymentPlanTable rows={rows} />
    </TableContainer>
  );
};
