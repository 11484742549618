import { Translate } from '@hooks/useTranslation/types';

interface IFormatOptionalLabel {
  labelKey: string;
  optional?: boolean;
  translate: Translate;
}

export const formatOptionalLabel = ({
  labelKey,
  optional = false,
  translate,
}: IFormatOptionalLabel): string =>
  translate(labelKey) + (optional ? ` (${translate('common.optional')})` : '');
