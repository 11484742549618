import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Nullable } from '@heidi-pay/heidi-common-fe/types';
import { getOrderProfile } from '@instore/redux';
import { IBaseInputComponent } from '@transaction/components/types';
import { ButtonGroupPicker } from '../ButtonGroupPicker';
import { ConfirmationCheckbox } from '../ConfirmationCheckbox';
import { dateFormatting } from '../DateOfBirthInput/validation';
import { IdentityCheckMethod } from './enums';
import { SwissNationalityVerification } from './SwissNationalityVerification';
import {
  ISwissManualIdentityVerificationError,
  ISwissManualIdentityVerificationOptions,
  ISwissManualIdentityVerificationValue,
  ISwissNationalityVerificationOptions,
  ISwissNationalityVerificationValue,
} from './types';
import { getValidationSchema } from './validation/getValidationSchemaManualIdentityVerification';

export const SwissManualIdentityVerification = (
  props: IBaseInputComponent<
    ISwissManualIdentityVerificationOptions,
    ISwissManualIdentityVerificationValue,
    ISwissManualIdentityVerificationError
  >,
) => {
  const orderProfile = useSelector(getOrderProfile);
  const [swissManualIdentityVerification, setSwissManualIdentityVerification] =
    useState<string>(IdentityCheckMethod.AutoIdVerification);
  const [nationality, setNationality] = useState<string>();
  const [residencePermit, setResidencePermit] = useState<Nullable<string>>();
  const [dateOfEntry, setDateOfEntry] = useState<Nullable<Date>>();
  const [confirmation, setConfirmation] = useState(false);

  const isManualIdActivated = orderProfile?.manualIdActivated ?? false;
  const isManualIdUploadMandatory =
    orderProfile?.manualIdUploadMandatory ?? false;
  const { error, options, setValue } = props;

  const { swissManualIdentityVerificationOptions } =
    options as ISwissManualIdentityVerificationOptions;

  const filteredOptions = {
    ...swissManualIdentityVerificationOptions,
    items: swissManualIdentityVerificationOptions.items.filter(
      option =>
        isManualIdActivated ||
        option === IdentityCheckMethod.AutoIdVerification,
    ),
  };

  useEffect(() => {
    const formattedDate = dateOfEntry ? dateFormatting(dateOfEntry) : null;
    setValue(
      props.name,
      {
        isManualIdActivated,
        isManualIdUploadMandatory,
        selectedSwissManualIdentityVerification:
          swissManualIdentityVerification,
        selectedNationality: nationality,
        selectedResidencePermit: residencePermit,
        selectedDateOfEntry: formattedDate,
        selectedConfirmation: confirmation,
      },
      { shouldValidate: true },
    );
  }, [
    confirmation,
    dateOfEntry,
    swissManualIdentityVerification,
    nationality,
    props.name,
    residencePermit,
    setValue,
    isManualIdActivated,
    isManualIdUploadMandatory,
  ]);

  const isManualVerification =
    swissManualIdentityVerification ===
    IdentityCheckMethod.ManualIdVerification;

  const optional = !isManualVerification;

  const handleOnChangeNationality = useCallback(
    (val: ISwissNationalityVerificationValue) => {
      const {
        selectedDateOfEntry,
        selectedNationality,
        selectedResidencePermit,
      } = val;

      setNationality(selectedNationality);
      setResidencePermit(selectedResidencePermit);
      setDateOfEntry(selectedDateOfEntry);
    },
    [],
  );

  return (
    <>
      <ButtonGroupPicker
        {...props}
        error={error?.selectedSwissManualIdentityVerification?.message}
        name="swissManualIdentityVerificationValue"
        onChange={setSwissManualIdentityVerification}
        options={filteredOptions}
        value={swissManualIdentityVerification}
      />
      <>
        <SwissNationalityVerification
          {...props}
          onChange={handleOnChangeNationality}
          options={
            {
              ...options,
              optional,
            } as ISwissNationalityVerificationOptions
          }
        />
        {isManualVerification ? (
          <ConfirmationCheckbox
            {...props}
            error={error?.selectedConfirmation?.message}
            name="swissManualIdentityVerificationConfirmation"
            onChange={setConfirmation}
            options={{
              labelPlacement: 'end',
              textAlign: 'left',
            }}
            value={confirmation}
          />
        ) : null}
      </>
    </>
  );
};

SwissManualIdentityVerification.validation = getValidationSchema;
