import { useCallback, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Grid } from '@mui/material';
import {
  TextInput,
  TextInputNumeric,
} from '../../../../transaction/components/heylight';
import { InstoreProductSearchTextInput } from '../InstoreProductSearchTextInput';
import { VariantsPicker } from '../VariantsPicker';
import { ControlledField } from './components/ControlledField';
import { FieldNames } from './enums';
import { updateProductValue } from './utils/helpers';

export const InstoreMobilezoneTradeInProductForm = memo(
  ({ control, currency, errors, index, setValue }) => {
    const updateProduct = useMemo(
      () => updateProductValue(index, setValue),
      [index, setValue],
    );

    const handleSearchResult = useCallback(
      productDetails => {
        updateProduct(FieldNames.Price, productDetails?.expectedPrice);
        updateProduct(FieldNames.ProductName, productDetails?.name);
        updateProduct(FieldNames.ProductIdentity, productDetails?.identity);
        updateProduct(
          FieldNames.AdditionalDetails,
          productDetails?.additionalDetails,
          false,
        );
      },
      [updateProduct],
    );

    return (
      <>
        <ControlledField
          Component={InstoreProductSearchTextInput}
          control={control}
          controlName={`products.${index}.${FieldNames.ProductSku}`}
          error={errors?.products?.[index]?.[FieldNames.ProductSku]?.message}
          id={FieldNames.ProductSku}
          name={FieldNames.ProductSku}
          onSearchResult={handleSearchResult}
          options={{
            disabled: true,
            optional: true,
            priceFieldName: FieldNames.Price,
            identityFieldName: FieldNames.ProductIdentity,
            productNameField: FieldNames.ProductName,
            productAdditionalDetailsField: FieldNames.AdditionalDetails,
          }}
          setValue={setValue}
        />
        <ControlledField
          Component={TextInput}
          control={control}
          controlName={`products.${index}.${FieldNames.ProductIdentity}`}
          error={
            errors?.products?.[index]?.[FieldNames.ProductIdentity]?.message
          }
          id={FieldNames.ProductIdentity}
          name={FieldNames.ProductIdentity}
          options={{ disabled: false }}
          setValue={setValue}
        />
        <ControlledField
          Component={TextInputNumeric}
          control={control}
          controlName={`products.${index}.${FieldNames.Price}`}
          defaultValue={{ amount: '', currency }}
          error={errors?.products?.[index]?.[FieldNames.Price]?.message}
          id={FieldNames.Price}
          name={FieldNames.Price}
          options={{ disabled: true, type: 'monetary' }}
          setValue={setValue}
        />
        <ControlledField
          Component={TextInput}
          control={control}
          controlName={`products.${index}.${FieldNames.ProductName}`}
          error={errors?.products?.[index]?.[FieldNames.ProductName]?.message}
          id={FieldNames.ProductName}
          name={FieldNames.ProductName}
          options={{ disabled: true }}
          setValue={setValue}
        />
        <Grid container={true}>
          <Grid item={true} xs={6}>
            <ControlledField
              Component={VariantsPicker}
              control={control}
              controlName={`products.${index}.${FieldNames.ProductVariant}`}
              id={FieldNames.ProductVariant}
              name={FieldNames.ProductVariant}
              options={{ hidden: true, priceFieldName: FieldNames.Price }}
              setValue={setValue}
            />
          </Grid>
        </Grid>
        <ControlledField
          Component={TextInput}
          control={control}
          controlName={`products.${index}.${FieldNames.AdditionalDetails}`}
          id={FieldNames.AdditionalDetails}
          name={FieldNames.AdditionalDetails}
          options={{ hidden: true }}
          setValue={setValue}
        />
      </>
    );
  },
);

InstoreMobilezoneTradeInProductForm.propTypes = {
  control: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  errors: PropTypes.object,
  index: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};

export const validation = () =>
  yup
    .object()
    .transform(form => {
      if (form.submitAttempted) {
        form.trigger();
      }
      const formValues = form.getValues();
      return { ...form, ...formValues };
    })
    .test('instoreMobilezoneTradeInProductForm', 'ERROR', form => {
      return !form?.hasErrors();
    })
    .nullable();

InstoreMobilezoneTradeInProductForm.displayName =
  'InstoreMobilezoneTradeInProductForm';
InstoreMobilezoneTradeInProductForm.validation = validation;
