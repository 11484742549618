import { useTheme } from '@mui/material';

interface ITheme {
  backgroundColor?: string;
  textColor?: string;
}

interface ICustomStyles {
  buttonsBorderRadius?: string;
  fontFamily?: string[];
  loaderColor?: string;
  primaryBgColor?: string;
  primaryTextColor?: string;
  secondaryBgColor?: string;
  secondaryTextColor?: string;
  theme?: ITheme;
}

export const useCustomStyles = (): ICustomStyles => {
  const { borderRadius, palette } = useTheme();

  return {
    primaryBgColor: palette.primary.main,
    buttonsBorderRadius: borderRadius.sm,
    loaderColor: palette.primary.main,
  };
};
